import callImg from "img/appCall/alexandra_zoe_worden_calling.jpg";
import callWithScientist from "img/appCall/call_with_alexandra_zoe_worden.jpg";
// import imgOnFloorTablet from "img/appCall/ks_shack_charles_nye_tablet.png";

const appData = {
  callImg: callImg,
  callWithScientist: callWithScientist,
  scientistName: "Alexandra Zoe Worden",
  scientistTitle: "MBARI Microbial Ecologist and Biologist"
  // imgOnFloorTablet: imgOnFloorTablet
};
export default appData;
