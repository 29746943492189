import { Machine } from "xstate";

export const mbariMachine = Machine({
  id: "mbari",
  initial: "welcome_explorer",
  states: {
    welcome_explorer: {
      on: { "": "reading_welcome_message" } //skip welcome_explorer - might one day be needed
    },
    reading_welcome_message: {
      initial: "one",
      states: {
        one: {
          one: { NEXT: "two" }
        },
        two: {
          two: { NEXT: "three" }
        },
        three: {
          three: { NEXT: "explore_map" }
        }
      }
    },
    explore_map: {}
  }
});

let currentState = mbariMachine.initialState;

export function send(event) {
  currentState = mbariMachine.transition(currentState, event);
  console.log(currentState.value);
}

const SiteStates = {
  WELCOME: [],
  EXPLORE_LAB: [],
  DOOR_UNLOCKED: []
};

export default SiteStates;
