import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import { Tablet } from "components/Tablet";
import AppBlastQuiz from "components/AppBlastQuiz";
import UserContext from "context/User";
import { Environment } from "components/Environment";
// import { NewLargeTablet } from "components/UIComponents";
import imgPageBackground from "img/ks_shack_background_tablet.jpg";
import imgPageBackgroundSm from "img/ks_shack_background_tablet_sm.jpg";
import multipleChoice from "custom/lab/BlastQuiz";
import bqImg1 from "img/elk_dnd_1.jpg";
import bqImg2 from "img/elk_dnd_2.jpg";
import bqImg3 from "img/elk_dnd_3.jpg";
// import bqROV from "img/bq_rov.png";
// import bqDSV from "img/bq_dsv.png";
// import tabletBG from "img/tablet_background.jpg";
import imgOnFloorTablet from "img/ks_shack_quiz_tablet.png";
import imgFloorTabletHighlight from "img/ks_shack_floor_tablet_highlight.svg";

const quizItems = [
  {
    imgSrc: bqImg1,
    multiSelect: true,
    question: "What are some characteristics of an estuary?",
    choices: {
      1: "Mix of salt and freshwater",
      2: "A place that has little use today",
      3: "A place for animals to raise young",
      4: "A freshwater environment",
      5: "Helps clean water by absorbing impurities"
    },
    answers: [1, 3, 5]
  },
  {
    imgSrc: bqImg2,
    multiSelect: true,
    question: "Why is enterococci in the water a problem?",
    choices: {
      1: "It can indicate  possible sewage leaks",
      2: "It only kills birds",
      3: "It corrodes MBARI equipment",
      4: "It's a harmful pesticide"
    },

    answers: [1]
  },
  {
    imgSrc: bqImg3,
    multiSelect: true,
    question: "How have MBARI  scientists made testing water easier?",
    choices: {
      1: "They have created a machine to prepare water samples",
      2: "They have hired more people to test the water",
      3: "They have bought a new water-cleaning machine",
      4: "They haven’t figured out a solution yet"
    },
    answers: [1]
  }
];
const theQuiz = new multipleChoice(quizItems);

// function subTwoPercentages(one, two) {
//   return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
// }
export const envMachine = Machine(
  {
    id: "quiz",
    context: {
      environment: {
        className: "env_shack",
        shiftBackground: true,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgPageBackground,
          thumbnail: imgPageBackgroundSm,
          alt: "Kayak Shack Blast Quiz",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          floorTablet: {
            visible: true,
            clickable: true,
            showHighlight: false,
            ringing: false,
            action: "pickUpTablet",
            className: "floor_tablet",
            shiftedStyle: {},
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: imgOnFloorTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  bottom: "10%"
                },
                aspectRatio: "179x211"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgFloorTabletHighlight,
                style: {
                  position: "absolute",
                  left: "45.5%",
                  width: "12%",
                  bottom: "8%"
                },
                aspectRatio: "179x211"
              }
            ]
          }
        }
      },
      showTablet: true
    },
    initial: "shackScene",
    states: {
      shackScene: {
        on: {
          TOUCH: {
            target: "done",
            actions: assign({ done: true })
          }
        }
      },

      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.clipboard.showHighlight = true;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

const TabletContent = props => {
  const { current } = props;
  // console.log(current.value);
  switch (current.value) {
    default:
      return (
        <AppBlastQuiz
          tabletSend={props.mSend}
          nextStep={props.nextStep}
          quiz={theQuiz}
        />
      );
  }
};

const Clipboard = props => {
  const [user] = useContext(UserContext);
  // const ringRef = useRef();
  user.level = props.lesson;
  user.objective = "Elkhorn Slough & Kayak Shack";
  const [current, mSend] = useMachine(envMachine);
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  return (
    <Environment
      {...props}
      environment={current.context.environment}
      itemStored={current.context.itemStored}
      mSend={props.mSend}
    >
      {/* showTablet={current.context.showTablet} */}
      <Tablet>
        <TabletContent {...props} mSend={mSend} current={current} />
      </Tablet>
    </Environment>
  );
};

export default Clipboard;
