import React, { useContext } from "react";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Lab from "components/Lab";
import { Redirect } from "react-router-dom";
import UserContext from "context/User.js";
import TrophyTablet from "components/TrophyTablet";
export const finalMachine = Machine({
  id: "trophy_room",
  context: {
    done: false
  },
  initial: "trophyPanel",
  states: {
    trophyPanel: {
      on: {
        DONE: {
          target: "done",
          actions: assign({ done: true })
        }
      }
    },

    done: {
      type: "final"
    }
  }
});

const Final = props => {
  const [current, mSend] = useMachine(finalMachine);
  const { done } = current.context;
  const [user] = useContext(UserContext);

  user.level = 2;
  user.objective = "Claim Reward";

  console.log(done);
  if (done) {
    return <Redirect to={props.nextStep} />;
  }

  return (
    <Lab
      objVideoCallInfo={null}
      objState={current}
      labSend={mSend}
      nextStep={props.nextStep}
      review={true}
    >
      <TrophyTablet mSend={mSend} {...props} />
    </Lab>
  );
};

export default Final;
