import React, { useState, useEffect, useCallback } from "react";
// import callImg from "img/scientist_emery_nolasco.png";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Lab from "components/Lab";
import { NewLargeTablet } from "components/UIComponents";
import { AskScientist, AppScreen } from "components/TabletApps";
// import callWithScientist from "img/emery_nolasco_call.png";
import tabletBG from "img/tablet_background.jpg";
import gsap from "gsap/dist/gsap";
import { Redirect } from "react-router-dom";
import { TimelineLite, CSSPlugin } from "gsap";
import FaArrowCircleRight from "react-icons/lib/fa/arrow-circle-right";
import FaArrowCircleLeft from "react-icons/lib/fa/arrow-circle-left";
import appData from "components/appCall/CallingEmeryNolasco";
import { ShowVideo } from "components/TabletApps";

//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(CSSPlugin);

export const labMachine = Machine({
  id: "askscientist",
  context: {
    chalkboardGlows: false,
    chalkboardClickable: false,
    labDoorIsUnlocked: true,
    labDoorClickable: false,
    callInProgress: false,
    callStep: 0,
    tabletApp: "",
    tabletClickable: false,
    tabletRinging: false,
    showTablet: true,
    deskTabletOverlay: false,
    done: false,
    scientistName: appData.scientistName,
    scientistTitle: appData.scientistTitle,
    callImg: appData.callImg,
    callWithScientist: appData.callWithScientist,
    scientistSubTitles: [
      "Hi, I am Emery Nolasco and I am an AUV Operations Engineer. My role at MBARI involves maintaining and operating AUVs.   There are many responsibilities associated with my position, but it is exciting to be creating new technology for exploring the ocean.",
      "If you like adventure and technology, you would love a career as an Operations Engineer of one of our many underwater vehicles.  Check out how we use our AUVs and other underwater tools here at MBARI."
    ]
  },
  initial: "appScreen",
  states: {
    appScreen: {
      on: {
        ASK: {
          target: "waitForPage",
          actions: assign({
            callInProgress: true,
            callConnected: true
          })
        }
      }
    },
    waitForPage: {
      after: {
        100: "askScientist"
      }
    },
    askScientist: {
      // on: {
      //   CLICK: {
      //     target: "onCall"
      //   }
      // }
      invoke: {
        src: "animateRing",
        onDone: {
          target: "onCall",
          actions: [
            () => console.log("IT RAN"),
            assign({ callConnected: false, inCall: true })
          ]
        }
      }
    },
    joinCall: {
      on: {
        "": {
          target: "onCall",
          actions: [
            // () => console.log("IT RAN"),
            assign({ callConnected: false, inCall: true })
          ]
        }
      }
    },
    onCall: {
      on: {
        FORWARD: [
          {
            target: "onCall",
            cond: (context, event) => {
              return context.callStep + 1 < context.scientistSubTitles.length;
            },
            actions: [
              assign({
                callStep: context => context.callStep + 1,
                callInProgress: true
              })
            ]
          },
          {
            target: "showVideo",
            actions: assign({
              callStep: 0,
              callInProgress: false
            })
          }
        ],
        BACK: [
          {
            target: "onCall",
            cond: (context, event) => {
              return context.callStep - 1 >= 0;
            },
            actions: assign({
              callStep: context => context.callStep - 1,
              callInProgress: true
            })
          },
          {
            actions: assign({
              callInProgress: true
            })
          }
        ]
      }
    },
    showVideo: {
      on: {
        VIDEODONE: {
          target: "videoDone",
          actions: assign({})
        }
      }
    },
    videoDone: {
      on: {
        "": {
          target: "done",
          actions: assign({
            done: true
          })
        }
      }
    },
    done: {
      type: "final"
    }
  }
});

const AskScientistApp = props => {
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  const animateRing = useCallback(
    (context, event) => {
      console.log("start this!");
      return new Promise(resolve => {
        ringTimeLine.eventCallback("onComplete", function() {
          console.log("on complete resolving");
          resolve();
        });
        ringTimeLine.restart();
      });
    },
    [ringTimeLine]
  );

  const [current, mSend] = useMachine(labMachine, {
    services: { animateRing }
  });

  const { showTablet, callStep, done } = current.context;
  console.log(done);
  if (done) {
    return <Redirect to={props.nextStep} />;
  }
  console.log("here");

  const OnCall = props => {
    const handleUserKeyPress = event => {
      const { keyCode } = event;

      if (keyCode === 32 && event.target === document.body) {
        mSend("FORWARD");
      }
      if (keyCode === 8 && event.target === document.body) {
        mSend("BACK");
      }
    };

    useEffect(() => {
      window.addEventListener("keyup", handleUserKeyPress);
      return () => {
        window.removeEventListener("keyup", handleUserKeyPress);
      };
    });

    return (
      <>
        <div className="as-bg">
          <img alt="Large Tablet - Screen" src={tabletBG} />
        </div>
        <div className="incall">
          <div className="as_video">
            <img
              src={current.context.callWithScientist}
              alt={current.context.scientistName}
            />
          </div>
          <div className="incall_text_box">
            <div className="as_video_overlay_text">
              <h1>{current.context.scientistName}</h1>
              <h2>({current.context.scientistTitle})</h2>
            </div>
            <div className="as_text">
              <p>{current.context.scientistSubTitles[callStep]}</p>
              <div className="incall_navigation">
                <FaArrowCircleLeft
                  className={callStep === 0 ? "noclick" : null}
                  onClick={callStep !== 0 ? () => mSend("BACK") : null}
                />
                <FaArrowCircleRight onClick={() => mSend("FORWARD")} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const TabletContent = () => {
    console.log(current.value);
    switch (current.value) {
      case "onCall":
        return <OnCall />;
      case "showVideo":
        return (
          <ShowVideo
            videoId="Hq-XERexZL4"
            videoTitle="High-tech robots reveal the deep"
            videoIntroduction="On land, we map details of the Earth with our eyes and hands, and use satellite images to trace out broader regions."
            videoCaption="Underwater robots can help map underwater landscapes."
            onEnd={() => mSend("VIDEODONE")}
            mSend={mSend}
            noButton={true}
          />
        );
      case "askScientist":
      case "waitForPage":
        return (
          <AskScientist
            current={current}
            msend={mSend}
            ringTimeLine={ringTimeLine}
          />
        );
      case "appScreen":
      default:
        return <AppScreen mSend={mSend} focus="AskScientist" />;
    }
  };
  console.log(current.context);
  return (
    <Lab objState={current}>
      <NewLargeTablet showTablet={showTablet}>
        <TabletContent />
      </NewLargeTablet>
    </Lab>
  );
};

export default AskScientistApp;
