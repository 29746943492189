import React from "react";
import Image from "lqip-react";
import ExplorerBadge from "./ExplorerBadge";

const OImg = props => {
  // console.log(props);
  if (props.optimize === true) {
    return (
      <Image
        src={props.src}
        thumbnail={props.thumbnail}
        aspectRatio={props.aspectRatio}
        alt={props.alt}
        color={props.color}
      />
    );
  } else {
    return <img src={props.src} alt={props.alt} />;
  }
};

const RenderClickables = props => {
  const layers = props.layers;
  let visibilityStyle = {};
  return layers.map((layer, key) => {
    visibilityStyle = {};
    if (props.visible === false && layer.name !== "background") return "";
    const hoverClass =
      props.showHover && layer.handlesHover ? "show_hover" : null;
    const ringClass = layer.handlesRing && props.ringing ? "blinking" : null;
    console.log(layer.name);
    console.log(layer.handlesHover);
    const className = [hoverClass, ringClass].join(" ");

    const onClick = layer.handlesClick ? () => props.mSend(props.action) : null;

    if (layer.handlesHighlight === true && props.showHighlight === false)
      visibilityStyle = { display: "none" };
    if (layer.handlesLock === true && props.locked === false)
      visibilityStyle = { display: "none" };

    // layer.style["display"] = "none";
    return (
      <img
        style={{ ...layer.style, ...visibilityStyle }}
        src={layer.src}
        alt={layer.alt}
        key={key}
        className={className}
        onClick={onClick}
      />
    );
  });
};

export const Environment = props => {
  // const [user, setUser] = useContext(UserContext);
  const { mSend, environment } = props;
  const { background, clickables } = environment;

  return (
    // <div style={{ position: "absolute", width: "100%" }}>
    <div
      className={
        "mbari_container " +
        environment.className +
        (environment.shiftBackground ? " shift" : "")
      }
    >
      <OImg {...background} />
      {props.showBadge !== false && (
        <ExplorerBadge objective="Explore MBARI" {...props} />
      )}
      {clickables &&
        Object.keys(clickables).map((clickableName, index) => {
          const clickable = clickables[clickableName];

          // console.log(clickable);
          clickable["mSend"] = mSend;

          return (
            <div className={clickable.className} key={index}>
              <RenderClickables {...clickable} name={clickableName} />
            </div>
          );
        })}
      {props.children}
    </div>
  );
};
