import React, { useState, useCallback } from "react";
// import callImg from "img/scientist_emery_nolasco.png";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
// import LabRoom from "components/LabRoom";
import Lab from "components/Lab";
import { NewLargeTablet } from "components/UIComponents";
// import tabletBG from "img/tablet_background.png";
import {
  NotesSelection,
  Intro,
  AppScreen,
  Reflection
} from "components/TabletApps";
import gsap from "gsap/dist/gsap";
import { Redirect } from "react-router-dom";
import { TimelineLite, CSSPlugin } from "gsap";

//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(CSSPlugin);

const morePages = (context, event) => {
  return context.page > 1;
};
export const notesMachine = Machine(
  {
    id: "notes",
    context: {
      chalkboardGlows: false,
      chalkboardClickable: false,
      labDoorIsUnlocked: true,
      labDoorClickable: false,
      callInProgress: false,
      callStep: 0,
      tabletApp: "",
      tabletClickable: false,
      tabletRinging: false,
      showTablet: true,
      deskTabletOverlay: false,
      done: false,
      backArrowClickable: false,
      forwardArrowClickable: true,
      parentDir: "Notes",
      currentFile: "Foreword"
    },
    initial: "appScreen",
    states: {
      appScreen: {
        on: {
          NOTES: {
            target: "intro",
            actions: assign({
              backClickable: false,
              forwardArrowClickable: true,
              page: 1
            })
          }
        }
      },
      intro: {
        on: {
          NOTES: {
            target: "notesSelection",
            actions: assign({
              backClickable: true,
              currentFile: "Notes",
              parentDir: null
            })
          },
          FORWARDARROW: [
            {
              target: "intro",
              cond: morePages,
              actions: ["nextPage"]
            },
            {
              target: "notesSelection",
              actions: assign({
                page: 1,
                backClickable: true
              })
            }
          ],
          BACKARROW: [
            {
              target: "intro",
              cond: morePages,
              actions: ["prevPage"]
            },
            {
              target: "notesSelection",
              actions: assign({
                page: 1,
                backClickable: true
              })
            }
          ]
        }
      },
      notesSelection: {
        on: {
          BACK: {
            target: "intro",
            actions: assign({
              backClickable: false,
              currentFile: "Foreword",
              parentDir: "Notes"
            })
          },
          INTRO: {
            target: "intro",
            actions: assign({
              backClickable: true,
              currentFile: "Foreword",
              parentDir: "Notes"
            })
          },
          MBARIREFLECT: {
            target: "reflection",
            actions: assign({
              backClickable: true,
              currentFile: "MBARI Reflection",
              parentDir: "Notes"
            })
          },
          DONE: {
            target: "done",
            actions: assign({
              backClickable: true,
              currentFile: "MBARI Reflection",
              parentDir: "Notes",
              done: true
            })
          }
        }
      },
      reflection: {
        on: {
          TEXTFINISHED: [
            {
              target: "reflection",
              cond: morePages,
              actions: assign({
                qNum: context => context.qNum + 1
              })
            },
            {
              target: "reflection",
              actions: assign({
                canSave: true
              })
            }
          ],
          BACK: {
            target: "intro",
            actions: () => console.log("Should save")
          },
          SAVE: {
            target: "notesSelection",
            actions: assign({
              backClickable: true,
              currentFile: "Notes",
              parentDir: null
            })
          }
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      nextPage: (context, event) => {
        context.page = context.page + 1;
        context.backArrowClickable = true;
        context.forwardArrowClickable = false;
      },
      prevPage: (context, event) => {
        if (context.page > 1) {
          context.page = context.page - 1;
          context.backArrowClickable = true;
        } else {
          context.backArrowClickable = false;
        }
      }
    },
    guards: {
      morePages
    }
  }
);

const Notes = props => {
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  const animateRing = useCallback(
    (context, event) => {
      console.log("start this!");
      return new Promise(resolve => {
        ringTimeLine.eventCallback("onComplete", function() {
          console.log("on complete resolving");
          resolve();
        });
        ringTimeLine.restart();
      });
    },
    [ringTimeLine]
  );

  const [current, mSend] = useMachine(notesMachine, {
    services: { animateRing }
  });

  const {
    showTablet,
    done,
    page,
    backClickable,
    backArrowClickable,
    forwardArrowClickable,
    parentDir,
    currentFile
  } = current.context;
  // console.log(done);
  if (done) {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    console.log(current.value);
    switch (current.value) {
      case "appScreen":
        return <AppScreen mSend={mSend} focus="Notes" />;
      case "notesSelection":
        return <NotesSelection {...props} />;
      case "reflection":
        return <Reflection {...props} />;
      case "intro":
      default:
        return <Intro {...props} />;
    }
  };
  console.log(current.context);
  return (
    <Lab objState={current}>
      <NewLargeTablet showTablet={showTablet}>
        <TabletContent mSend={mSend} current={current} />
      </NewLargeTablet>
    </Lab>
  );
};

export default Notes;
