import React from "react";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import styled from "@emotion/styled";
import backgroundTablet from "img/lab_background_tablet.jpg";
import { Tablet } from "components/Tablet";
import AppBlastQuiz from "components/AppBlastQuiz";
import { DeskTablet } from "components/DeskTablet";
import ExplorerBadge from "components/ExplorerBadge";
import { useHover } from "components/HelperFunctions";
import { NavLink } from "react-router-dom";
// import dtBlastQuiz from "img/dt_blast_quiz.png";
import unlockedDoor from "img/lab_door.png";
import lockedArea from "img/locked_area.png";
import multipleChoice from "custom/lab/BlastQuiz";
import bqHammerheadShark from "img/bq_hammerhead_shark.png";
import bqSunkenShip from "img/bq_sunken_ship.png";
import bqROV from "img/bq_rov.png";
import bqDSV from "img/bq_dsv.png";

const quizItems = [
  {
    imgSrc: bqHammerheadShark,
    question:
      "We need to track the movements of a shark. Which tool would be best for this task?",
    choices: {
      1: "Autonomous Underwater Vehicle (AUV)",
      2: "Satellite Tracking Tag",
      3: "Remotely Operated Vehicle (ROV)",
      4: "Sound Navigation and Ranging (SONAR)"
    },
    answers: [2]
  },
  {
    imgSrc: bqSunkenShip,
    question:
      "We need to create an image of a shipwreck.  Which tool would be able to create this image?",
    choices: {
      1: "Satellite Tracking Tag",
      2: "Autonomous Underwater Vehicle (AUV)",
      3: "Sound Navigation and Ranging (SONAR)",
      4: "Remotely Operated Vehicle (ROV)"
    },

    answers: [3]
  },
  {
    imgSrc: bqDSV,
    question: "Which statement best explains why using a DSV can be dangerous?",
    choices: {
      1: "DSVs are controlled by a computer program",
      2: "SONAR controls the DSV",
      3: "DSV pilots can die due to a serious malfunction",
      4: "DSVs can be attacked"
    },
    answers: [3]
  },
  {
    imgSrc: bqROV,
    question:
      "Which tool is best to measure physical characteristics (such as temperature and salinity) of the water?",
    choices: {
      1: "Remotely Operated Vehicle (ROV)",
      2: "Sound Navigation and Ranging (SONAR)",
      3: "Autonomous Underwater Vehicle (AUV)",
      4: "Deep-submergence vehicle (DSV)"
    },
    answers: [3]
  }
];
const theQuiz = new multipleChoice(quizItems);

export const labMachine = Machine({
  id: "lab",
  context: {
    tabletClickable: false,
    tabletRinging: false,
    chalkboardGlows: false,
    chalkboardClickable: false,
    labDoorIsUnlocked: false,
    showTablet: true,
    callInProgress: false,
    callStep: 0,
    tabletApp: "appBlastQuiz",
    tabletOverlay: true
  },
  initial: "blastQuiz",
  states: {
    blastQuiz: {
      on: {
        TOUCH: {
          target: "done",
          actions: assign({ done: true })
        }
      }
    },

    done: {
      type: "final"
    }
  }
});

const LabContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  max-width: 1920px;
  line-height: 0;
  img {
    max-width: 100%;
  }
`;
const LabRoom = props => {
  const mode = props.mode || "lab_mode";

  return (
    <LabContainer className={mode}>
      <img alt="The MBARI Lab" src={backgroundTablet} />
      {props.children}
    </LabContainer>
  );
};

const TabletApp = props => {
  return <Tablet>{props.children}</Tablet>;
};

// const objVideoCallInfo = {
//   desktopSrc: dtBlastQuiz,
//   name: "Blast-Quiz"
// };

const BlastQuiz = props => {
  const [current, labSend] = useMachine(labMachine);
  const { nextStep } = props;
  const {
    // tabletRinging,
    // tabletClickable,
    // chalkboardGlows,
    // chalkboardClickable,
    labDoorIsUnlocked
    // showTablet,
    // callInProgress,
    // callStep,
    // tabletOverlay,
    // tabletApp,
    // calling
  } = current.context;

  const LabDoor = () => {
    const [hoverRef, isHovered] = useHover();
    const glow = isHovered ? "showglow" : "noglow";

    return (
      <NavLink to={nextStep}>
        <div className="lab_door" ref={hoverRef}>
          <img alt="Lab Door" src={unlockedDoor} className={glow} />
        </div>
      </NavLink>
    );
  };
  const Door = props => {
    if (labDoorIsUnlocked) {
      return <LabDoor />;
    }

    return (
      <div className="locked_area">
        <img alt="Locked Door" src={lockedArea} />
      </div>
    );
  };

  return (
    <LabRoom mode="tablet_mode">
      <ExplorerBadge objective="Explore MBARI" />

      {/* <DeskScreen /> */}
      <DeskTablet mode="tablet_mode" send={labSend} />
      {/* <ChalkBoard /> */}
      <TabletApp showTablet={true}>
        <AppBlastQuiz tabletSend={labSend} nextStep={nextStep} quiz={theQuiz} />
      </TabletApp>
      <Door />
    </LabRoom>
  );
};

export default BlastQuiz;
