function shuffle(array) {
  //fisherYates
  var count = array.length,
    randomnumber,
    temp;
  while (count) {
    randomnumber = (Math.random() * count--) | 0;
    temp = array[count];
    array[count] = array[randomnumber];
    array[randomnumber] = temp;
  }
}

export default class LessonTest {
  constructor(objAnswerKey) {
    this._answers = objAnswerKey;

    this._answerAbbrs = Object.keys(this._answers); //"HOV", "ROV", "AUV", "SONAR", "SAT_TRACK_TAG"];

    this._correct = 0;
    this._incorrect = 0;
    this._total = Object.keys(this._answers).length;
    this._testRecord = {};
    this._answerAbbrLookup = {};
    for (let i = 0; i < this._answerAbbrs.length; i++) {
      this._answerAbbrLookup[this._answerAbbrs[i]] = i;
    }
  }

  AnswerText(abbr) {
    console.log("answers: ", abbr, this._answers[abbr]);
    return this._answers[abbr].text;
  }
  get Answers() {
    return this._answers;
  }
  get AnswerAbbrs() {
    return this._answerAbbrs;
  }

  get answerKeys() {
    return Object.keys(this.Answers);
  }

  get randomAnswerKeys() {
    const randomAnswers = Object.keys(this.Answers);
    shuffle(randomAnswers);
    return randomAnswers;
  }

  perfectScore() {
    console.log(this._correct + " " + this._total);
    return this._correct === this._total;
  }

  posLookup(abbr) {
    console.log("abbr:", abbr);
    console.log("_answerAbbrLookup:", this._answerAbbrLookup);
    return this._answerAbbrLookup[abbr];
  }

  checkAnswer(pos, abbr) {
    return this._answerAbbrs[pos] === abbr ? true : false;
  }

  isCorrect(answerKey) {
    console.log(answerKey);
    // console.log(this._testRecord);
    if (!(answerKey in this._testRecord)) {
      //   console.log("here");
      this._testRecord[answerKey] = true;
      this._correct = this._correct + 1;
      this._incorrect = this._incorrect > 0 ? this._incorrect - 1 : 0;
    }
    // console.log(answerKey + ": " + this._correct);
    return this._correct;
  }

  isIncorrect(answerKey) {
    if (!(answerKey in this._testRecord)) {
      this._testRecord[answerKey] = false;
      this._correct = this._correct > 0 ? this._correct - 1 : 0;
      this._incorrect += 1;
    }
    return this._correct;
  }
}
