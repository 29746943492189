import React from "react";
import styled from "@emotion/styled";
import deskTabletGlow from "../img/desk_tablet_glow.png";
import { NavLink } from "react-router-dom";
export const DeskTablet = props => {
  const { send, nextStep } = props;
  const left = props.mode === "lab_mode" ? "37.5%" : "6.7%";
  const display = props.mode === "tablet_mode" ? "none" : "block";
  const visible = props.visible ? "visible" : "hidden";
  let DeskTabletDiv = styled.div`
    position: absolute;
    width: calc(124 / 1920 * 100%);
    left: ${left};
    top: calc(546 / 1080 * 100%);
    line-height: 0;

    img {
      width: 100%;
      display: ${display};
      visibility: ${visible};
    }
  `;

  if (nextStep) {
    return (
      <NavLink to={nextStep}>
        <DeskTabletDiv>
          <img
            alt="Tablet on Desk"
            src={deskTabletGlow}
            {...(props.mode === "lab_mode"
              ? { onClick: () => send("CLICK") }
              : null)}
          />
          {props.children}
        </DeskTabletDiv>
      </NavLink>
    );
  } else {
    return (
      <DeskTabletDiv>
        <img
          alt="Tablet on Desk"
          src={deskTabletGlow}
          {...(props.mode === "lab_mode"
            ? { onClick: () => send("CLICK") }
            : null)}
        />
        {props.children}
      </DeskTabletDiv>
    );
  }
};
