import React, { useState, useCallback, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet, DisplayObtainedItem } from "components/UIComponents";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import imgSlough1 from "img/kayak_collect/bg_kc_1.jpg";
import imgSlough1Small from "img/kayak_collect/bg_kc_1_sm.jpg";
import imgKayak from "img/kayak_collect/kayak_first_person.png";
import iconForward from "img/kayak_collect/nav_arrow_forward.svg";
// import imgSampleMap from "img/elk_samples_bg.jpg";
// import imgSampleMapSmall from "img/elk_samples_bg_sm.jpg";
import tabletBG from "img/tablet_background.jpg";
// import callImg from "img/charles_nye_calling.png";
import iconKayak from "img/kayak_collect/icon_kayak.svg";
// import callWithScientist from "img/call_with_charles_nye.png";
import gsap from "gsap/dist/gsap";
import { TimelineLite, CSSPlugin } from "gsap";
import UserContext from "context/User";
import { IconInstruction } from "components/TabletApps";
import imgHUD from "img/kayak_collect/hud_midline.svg";
import appData from "components/appCall/CallingCharlesNye";
// import Lesson from "components/Lesson1/lesson";
//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(CSSPlugin);

// function subTwoPercentages(one, two) {
//   return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
// }
export const envMachine = Machine(
  {
    id: "kayakCollect",
    context: {
      environment: {
        className: "env_shack",
        shiftBackground: true,

        background: {
          optimize: true,
          src: imgSlough1,
          thumbnail: imgSlough1Small,
          alt: "Elkorn Slough",
          color: "#132962",
          aspectRatio: "1920x1080"
        },

        clickables: {
          kayak: {
            visible: true,
            clickable: true,
            ringing: false,
            locked: false,
            showHighlight: false,
            action: "TAKE",
            className: "kc_kayak",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgKayak,
                style: {
                  width: "100%"
                }
              }
            ]
          },
          forwardNav: {
            visible: false,
            clickable: true,
            ringing: false,
            locked: false,
            showHighlight: false,
            showHover: true,
            action: "PRESS",
            layers: [
              {
                name: "overlay",
                handlesHover: true,
                handlesClick: true,
                style: {
                  position: "absolute",
                  top: "50%",
                  left: "46%",
                  width: "11%"
                },
                optimize: false,
                src: iconForward
              }
            ]
          },
          hud: {
            visible: false,
            clickable: false,
            ringing: false,
            locked: false,
            showHighlight: true,
            action: "PRESS",
            layers: [
              {
                name: "overlay",
                style: {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%"
                },
                optimize: false,
                src: imgHUD
              }
            ]
          }
        }
      },
      callStep: 0,
      callImg: appData.callImg,
      tabletBG: tabletBG,
      scientistName: appData.scientistName,
      scientistTitle: appData.scientistTitle,
      tabletIcon: iconKayak,
      tabletTitle: "Get in the boat!",
      tabletMessage: [
        <React.Fragment>
          Great job on land! Now, it's time to get your hands dirty. I mean,
          wet! On your way up the slough collecting water samples, you will also
          get to learn about some organisms that live here. <br />
          <br />
          <b>Thanks for grabbing the samples for us!</b>
        </React.Fragment>
      ],
      showTablet: true,
      itemObtained: null,
      showBadge: true
    },
    initial: "kayakStart",
    states: {
      kayakStart: {
        on: {
          GO: {
            target: "scene1",
            actions: ["setScene1"]
          }
        }
      },
      scene1: {
        on: {
          PRESS: {
            target: "scene2",
            actions: ["setScene2"]
          }
        }
      },
      scene2: {
        on: {
          PRESS: {
            target: "done",
            actions: ["setScene3"]
          }
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      setScene1: (context, event) => {
        context.showBadge = false;
        context.showTablet = false;
        context.showNavigation = true;
        context.environment.clickables.hud.visible = true;
        context.environment.clickables.forwardNav.visible = true;
        context.environment.clickables.kayak.className = "kc_kayak center";
      },
      setScene2: (context, event) => {},
      setScene3: (context, event) => {
        context.done = true;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        // context.environment.background.src = imgKayakShackTablet;
        // context.environment.background.thumbnail = imgKayakShackTabletSmall;
        // context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.floorTablet.showHighlight = false;
        context.environment.clickables.floorTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.clipboard.visible = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.kayak.visible = true;
        context.environment.clickables.kayak.locked = false;
        context.environment.clickables.kayak.showHighlight = true;
        context.environment.clickables.kayak.clickable = true;
        // context.environment.background.src = imgKayakShack;
        // context.environment.background.thumbnail = imgKayakShackSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

const KayakCollect = props => {
  const [user] = useContext(UserContext);
  // const ringRef = useRef();
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  user.level = props.lesson;
  user.objective = "Elkhorn Slough & Kayak Shack";
  const animateRing = useCallback(
    (context, event) => {
      console.log("start this!");
      return new Promise(resolve => {
        ringTimeLine.eventCallback("onComplete", function() {
          console.log("on complete resolving");
          resolve();
        });
        ringTimeLine.restart();
      });
    },
    [ringTimeLine]
  );
  const [current, mSend] = useMachine(envMachine, {
    services: { animateRing }
  });
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      case "kayakStart":
      default:
        return (
          <IconInstruction
            {...props}
            title={props.current.context.tabletTitle}
            message={props.current.context.tabletMessage}
            mSend={props.mSend}
            icon={props.current.context.tabletIcon}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment
      {...props}
      environment={current.context.environment}
      itemStored={current.context.itemStored}
      showBadge={current.context.showBadge}
      mSend={mSend}
    >
      {current.context.itemObtained && (
        <DisplayObtainedItem
          obtainable={current.context.itemObtained}
          mSend={mSend}
        />
      )}
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default KayakCollect;
