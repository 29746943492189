import React, { useContext } from "react";
import UserContext from "context/User";
import DragAndMatchTest from "components/DragAndMatchTest";
import LessonTest from "custom/lab/DragAndDropTest";
import imgTestGuide from "img/elk_clipboard_test_guide.jpg";
// import TAGImg from "img/test_turtle.jpg";
import imgBackground from "img/elk_clipboard_bg.jpg";
import imgBackgroundSmall from "img/elk_clipboard_bg_sm.jpg";

const objAnswerKey = {
  SLOUGH: { text: "Slough" },
  ESTUARY: { text: "Estuary" },
  PESTICIDES: {
    text: "Pesticides"
  },
  PEAT: {
    text: "Peat"
  }
};

const objTest = new LessonTest(objAnswerKey);

const ClipboardDnD = props => {
  const [user] = useContext(UserContext);
  user.level = props.lesson;
  user.objective = "Elkhorn Slough & Kayak Shack";

  return (
    <DragAndMatchTest
      {...props}
      parentClass={"clipboard"}
      objTest={objTest}
      background={imgBackground}
      backgroundSmall={imgBackgroundSmall}
      horizontal={true}
      guideImageSrc={imgTestGuide}
    />
    // <Environment
    //   {...props}
    //   environment={current.context.environment}
    //   itemStored={current.context.itemStored}
    //   mSend={props.mSend}
    // >
    //   {/* showTablet={current.context.showTablet} */}
    //   <Tablet>
    //     <TabletContent {...props} mSend={mSend} current={current} />
    //   </Tablet>
    // </Environment>
  );
};

export default ClipboardDnD;
