import React, { useEffect, useState, useRef } from "react";
import tabletFrame from "../img/large_tablet_frame.png";
import tabletFrameSmall from "../img/large_tablet_frame_sm.png";
import tabletBG from "../img/tablet_background.jpg";
import tabletBGSmall from "../img/tablet_background_sm.jpg";
import "components/UIComponents.css";
import { Droppable, Draggable } from "react-beautiful-dnd";

import cropRings from "img/crop_rings.svg";
import { ProgressiveImage } from "react-progressive-image-loading";

export const LargeTablet = props => {
  const showTablet = props.showTablet;

  return showTablet ? (
    <div className="large_tablet">
      <div className="large_tablet_frame">
        <ProgressiveImage
          preview={tabletFrameSmall}
          src={tabletFrame}
          transitionTime={500}
          transitionFunction="ease"
          render={(src, style) => (
            <img src={src} style={style} alt="Tablet Frame" />
          )}
        />
      </div>
      <div className="tablet_background">
        <ProgressiveImage
          preview={tabletBGSmall}
          src={tabletBG}
          transitionTime={500}
          transitionFunction="ease"
          render={(src, style) => (
            <img src={src} style={style} alt="Large Tablet - Screen" />
          )}
        />
      </div>
      {props.children}
    </div>
  ) : null;
};
export const NewLargeTablet = props => {
  const showTablet = props.showTablet;

  return showTablet ? (
    <>
      <div className="large_tablet_frame">
        <ProgressiveImage
          preview={tabletFrameSmall}
          src={tabletFrame}
          transitionTime={500}
          transitionFunction="ease"
          render={(src, style) => (
            <img src={src} style={style} alt="Tablet Frame" />
          )}
        />
      </div>
      {props.children}
    </>
  ) : null;
};

const DistanceSelector = props => {
  //eslint-disable-next-line
  const [distance, setDistance] = useState(props.distance);

  // const handleChange = new_value => {
  //   console.log("new value", new_value);
  //   setDistance(new_value);
  // };

  // const choices = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <div className="auv_distance_selector" contentEditable={true}>
      {distance}
    </div>
    // <select>
    //   {choices.map((value, index) => {
    //     return (
    //       <option selected={value === distance && "selected"}>{value}</option>
    //     );
    //   })}
    // </select>
    // <FormControl as="select">
    //   {choices.map((value, index) => {
    //     return (
    //       <option selected={value === distance && "selected"}>{value}</option>
    //     );
    //   })}
    // </FormControl>

    // <InputNumber
    //   min={1}
    //   max={7}
    //   defaultValue={distance}
    //   onChange={handleChange}
    // />
  );
};

export const Copyable = props => {
  return (
    <Droppable droppableId={props.droppableId} isDropDisabled={true}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className={props.className}>
          {props.items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <React.Fragment>
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    className={
                      snapshot.isDragging
                        ? "auv_dragging auv_control_button"
                        : "auv_control_button" +
                          (item.selectable === false ? " not_selectable" : "")
                    }
                  >
                    {item.icon ? (
                      <div className={"auv_button_icon"}>
                        <img
                          src={item.icon}
                          alt={item.label}
                          className={item.rotate ? " r" + item.rotate : null}
                        />
                      </div>
                    ) : null}
                    {item.label}
                    {item.distance && (
                      <DistanceSelector distance={item.distance} />
                    )}
                  </div>
                  {snapshot.isDragging && (
                    <div className="dragging_object_copy auv_control_button">
                      {item.icon ? (
                        <div className={"auv_button_icon"}>
                          <img
                            src={item.icon}
                            alt={item.label}
                            className={item.rotate ? " r" + item.rotate : null}
                          />
                        </div>
                      ) : null}
                      {item.label}
                      {item.distance && <DistanceSelector />}
                    </div>
                  )}
                </React.Fragment>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export const AUVControlPad = props => {
  return (
    <Copyable
      droppableId="CONTROLPAD"
      className="auv_control_pad"
      items={props.items}
    />
  );
};

export const AUVProgramPad = props => {
  return (
    <Droppable droppableId="PROGRAMPAD">
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className="auv_program_pad">
          {props.items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={provided.draggableProps.style}
                  className="auv_control_button"
                >
                  {item.icon ? (
                    <div className={"auv_button_icon"}>
                      <img
                        src={item.icon}
                        alt={item.label}
                        className={item.rotate ? " r" + item.rotate : null}
                      />
                    </div>
                  ) : null}
                  {item.label}
                  {item.distance && <DistanceSelector />}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export const DisplayObtainedItem = props => {
  const obtainable = props.obtainable;

  return (
    <div
      className="clickable"
      onClick={() => props.mSend("STORE")}
      style={{
        position: "absolute",

        borderRadius: "999px",
        width: "54%",
        height: "18.6%",
        left: "21%",
        top: "76%",
        backgroundColor: "#132962B3"
      }}
    >
      <div
        style={{
          width: "15%",
          margin: "2.5% 0% 2.5% 4%",
          float: "left"
        }}
      >
        <img
          style={{ width: "100%" }}
          src={obtainable.src}
          alt={obtainable.title}
        />
      </div>
      <div
        style={{
          margin: "4% 2%",
          height: "70%",
          float: "left",
          width: "70%"
          // border: "1px solid green"
        }}
      >
        <h3
          style={{
            marginBlockStart: "0",
            marginBlockEnd: "1vw",
            textTransform: "uppercase",
            fontSize: "1vw",
            color: "#ffffffB1"
          }}
        >
          Item Obtained
        </h3>
        <h1
          style={{
            fontSize: "1.8vw",
            lineHeight: "1.8vw",
            marginBlockStart: "0",
            marginBlockEnd: ".5vw"
          }}
        >
          {obtainable.title}
        </h1>
        <h2
          style={{
            fontSize: "1vw",
            lineHeight: "1.4vw",
            color: "#ffffffB1",
            marginBlockStart: "0",
            marginBlockEnd: "0"
          }}
        >
          {obtainable.description}
        </h2>
      </div>
    </div>
  );
};
