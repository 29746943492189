import React, { useContext } from "react";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import { TabletButton } from "components/Tablet";
import trophyFrame from "img/trophy_frame.png";
import trophyBG from "img/trophy_bg.jpg";
import trophyMBARI from "img/trophy_MBARI.png";
import trophyKayak from "img/trophy_kayak.png";
import trophyTidePool from "img/trophy_tide_pool.png";
// import profilePicture from "img/lily_wilson_profile.png";
import trophyImg from "img/elkhorn_trophy_slough.jpg";
import { Redirect } from "react-router-dom";
import FaCheckCircle from "react-icons/lib/fa/check-circle";
import trophyLevelBG from "img/trophy_counter_circle.png";
import { TrophyExplorerBadge } from "components/ExplorerBadge";
import UserContext from "context/User.js";
import { Environment } from "components/Environment";

import imgKayakStationTablet from "img/ks_background_tablet.jpg";
import imgKayakStationTabletSmall from "img/ks_background_tablet_sm.jpg";

export const finalMachine = Machine({
  id: "trophy_room",
  context: {
    environment: {
      className: "env_elkhorn blur",
      shiftBackground: false,
      background: {
        optimize: false,
        src: imgKayakStationTabletSmall,
        thumbnail: imgKayakStationTabletSmall,
        alt: "Elkorn Slough Kayak Station",
        color: "#132962",
        aspectRatio: "1920x1080"
      },
      done: false
    }
  },
  initial: "trophyPanel",
  states: {
    trophyPanel: {
      on: {
        DONE: {
          target: "done",
          actions: assign({ done: true })
        }
      }
    },

    done: {
      type: "final"
    }
  }
});

const sections = [
  "Objectives",
  "Locations",
  "Data Base",
  "Activities",
  "Gallery"
];
const trophies = [
  { img: trophyMBARI, name: "MBARI Trophy" },
  { img: trophyKayak, name: "Kayak Trophy" },
  { img: trophyTidePool, name: "Tide Pool Trophy" }
];

// const myTrophies = ["MBARI Trophy"];
const MC = props => {
  return (
    <div className={props.className}>
      <img src={props.src} alt={props.alt} />
      {props.children}
    </div>
  );
};

const Tabs = props => {
  return (
    <div className="trophy_tab_container">
      {sections.map((section, count) => {
        return (
          <div
            className={
              "trophy_tabs" + (props.highlight === count ? " selected" : "")
            }
          >
            {section}
          </div>
        );
      })}
    </div>
  );
};
const InfoCell = props => {
  const classDef = ["info_cell", props.selected, props.size]
    .filter(n => n)
    .join(" ");

  const baseStyle = { marginLeft: "2%", textAlign: "center" };
  return (
    <div className={classDef}>
      {props.src && <img src={props.src} alt={props.alt} />}

      <h1>
        {props.title}
        <FaCheckCircle
          style={
            props.selected ? baseStyle : { ...baseStyle, color: "#ffd702" }
          }
          size={"2vw"}
        />
      </h1>
      <p>{props.children}</p>
      {props.button && (
        <TabletButton onClick={props.action}>{props.button}</TabletButton>
      )}
    </div>
  );
};
const LeftView = props => {
  return <div className="trophy_cell left">{props.children}</div>;
};
const RightView = props => {
  return <div className="trophy_cell right">{props.children}</div>;
};

const Final = props => {
  const [current, mSend] = useMachine(finalMachine);
  const { done } = current.context;
  const [user, setUser] = useContext(UserContext);

  user.objective = "Claim Reward";
  user.level = 3;

  console.log(done);
  if (done) {
    return <Redirect to={props.nextStep} />;
  }
  const TrophyTablet = props => {
    return (
      <div className="trophy_tablet">
        <MC className="trophy_bg" src={trophyBG} alt="Underwater" />
        <MC
          className="trophy_frame"
          src={trophyFrame}
          alt="Review your trophies"
        />
        <div className="trophy_screen">
          <Tabs highlight={0} />
          <LeftView>
            <InfoCell
              title="Elkhorn Slough and Kayak Station"
              selected="selected"
              size="thirty"
            >
              The Elkhorn Slough is a magical place full of wildlife and beauty.
              Meandering seven miles inland from the coast in the center of the
              picturesque Monterey Bay.
            </InfoCell>
            <InfoCell title="Welcome to MBARI" size="thirty">
              Monterey Bay Aquarium Research Institute (MBARI) is a non-profit
              ocean research center in California. Well... Investigate!
            </InfoCell>
            <InfoCell title="Explore the Map" size="thirty">
              Welcome to Monterey Bay area. Explore the map and start
              investigating.
            </InfoCell>
          </LeftView>
          <RightView>
            <InfoCell
              title="ELKHORN SLOUGH AND KAYAK STATION"
              src={trophyImg}
              size="onehundred"
              button="Done!"
              action={() => mSend("DONE")}
            >
              The Elkhorn Slough is a magical place full of wildlife and beauty.
              Meandering seven miles inland from the coast in the center of the
              picturesque Monterey Bay, the Elkhorn Slough harbors the largest
              tract of tidal salt marsh in California outside of San Francisco
              Bay. This biologically rich estuary provides habitat for a
              diversity of resident and migratory birds, plants, marine mammals
              and fish, and has been identified as a Globally Important Bird
              Area by the American Birding Conservancy.
            </InfoCell>
          </RightView>
        </div>

        <MC
          className="trophy_level_bg"
          src={trophyLevelBG}
          alt="Your new Level"
        ></MC>

        <TrophyExplorerBadge />
        <div className="trophy_label">Trophies:</div>
        <div className="trophy_room_label">Trophy Room</div>
        <div className="trophy_collection">
          {trophies.slice(0, 2).map(trophy => {
            return (
              <MC className="trophy_badge" src={trophy.img} alt={trophy.name} />
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <Environment
      {...props}
      showBadge={false}
      environment={current.context.environment}
      itemStored={current.context.itemStored}
      mSend={mSend}
    >
      <TrophyTablet mSend={mSend} {...props} />
    </Environment>
  );
};

export default Final;
