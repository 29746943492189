import React, { useState, useCallback, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet, DisplayObtainedItem } from "components/UIComponents";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import imgKayakShack from "img/ks_shack_background.jpg";
import imgKayakShackSmall from "img/ks_shack_background_sm.jpg";
import imgKayakShackTablet from "img/ks_shack_background_tablet.jpg";
import imgKayakShackTabletSmall from "img/ks_shack_background_tablet_sm.jpg";
import imgClipboardHighlight from "img/ks_shack_clipboard_highlight.svg";
import imgFloorTabletHighlight from "img/ks_shack_floor_tablet_highlight.svg";
import imgLockedKayak from "img/ks_shack_locked_kayak.svg";
import imgKayakHighlight from "img/ks_shack_kayak_highlight.svg";

import imgSampleMap from "img/elk_samples_bg.jpg";
import imgSampleMapSmall from "img/elk_samples_bg_sm.jpg";
// import imgQPCR from "img/ks_qPCR_obtained.png";
// import FaArrowCircleRight from "react-icons/lib/fa/arrow-circle-right";
// import FaArrowCircleLeft from "react-icons/lib/fa/arrow-circle-left";
import tabletBG from "img/tablet_background.jpg";
import gsap from "gsap/dist/gsap";
import { TimelineLite, CSSPlugin } from "gsap";
import UserContext from "context/User";

import { CallWSubTitles, ManualInitiateCall } from "components/TabletApps";
import appData from "components/appCall/CallingCharlesNye";
// import Lesson from "components/Lesson1/lesson";
//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(CSSPlugin);

// function subTwoPercentages(one, two) {
//   return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
// }
export const envMachine = Machine(
  {
    id: "kayakShack",
    context: {
      environment: {
        className: "env_shack",
        shiftBackground: true,
        background: {
          optimize: true,
          src: imgKayakShackTablet,
          thumbnail: imgKayakShackTabletSmall,
          alt: "Elkorn Slough Kayak Shack",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          floorTablet: {
            visible: false,
            clickable: false,
            showHighlight: false,
            ringing: false,
            action: "pickUpTablet",
            className: "floor_tablet",
            shiftedStyle: {},
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: appData.imgOnFloorTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  bottom: "10%"
                },
                aspectRatio: "179x211"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgFloorTabletHighlight,
                style: {
                  position: "absolute",
                  left: "45.5%",
                  width: "12%",
                  bottom: "8%"
                },
                aspectRatio: "179x211"
              }
            ]
          },
          clipboard: {
            visible: true,
            clickable: false,
            showHighlight: false,
            ringing: false,
            action: "TOUCH",
            className: "clipboard",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                optimize: false,
                src: imgClipboardHighlight,
                style: {
                  position: "absolute",
                  left: "46%",
                  width: "18%",
                  top: "73.0%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          kayak: {
            visible: true,
            clickable: true,
            ringing: false,
            locked: true,
            showHighlight: true,
            showHover: true,
            action: "TAKE",
            className: "locked_kayak",

            shiftedStyle: {},
            layers: [
              {
                name: "overlay",
                handlesClick: false,
                handlesHighlight: false,
                handlesLock: true,
                optimize: false,
                src: imgLockedKayak,
                style: {
                  width: "100%"
                }
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesLock: false,
                optimize: false,
                handlesHover: true,
                src: imgKayakHighlight,
                style: {
                  position: "absolute",
                  width: "100%",
                  left: "0",
                  top: "0"
                }
              }
            ]
          }
        }
      },
      callStep: 0,
      callImg: appData.callImg,
      tabletBG: tabletBG,
      scientistName: appData.scientistName,
      scientistTitle: appData.scientistTitle,
      videoStill: appData.callWithScientist,
      scientistSubTitles: [
        "“FIRSTNAME, we need your help! We detected an increase in enterococci bacteria in the Bay. A little girl even came down with a sickness from bacteria. She is in the hospital, but she will be alright...”",
        "“Take one of the kayaks out and collect water samples so that we can help figure out the source of the bacterial contamination.”"
      ],
      showTablet: true,
      itemObtained: null
    },
    initial: "shackScene",
    states: {
      shackScene: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: ["changeBackground"]
          },
          TAKE: {
            target: "done"
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            // target: "engageInCall",
            actions: [assign({})]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "shackScene",
              actions: "finishCall"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      changeBackground: (context, event) => {
        // console.log("HERE!");
        context.environment.background.src = imgSampleMap;
        context.environment.background.src = imgSampleMapSmall;
        context.callConnected = true;
        context.environment.clickables.kayak.visible = false;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakShackTablet;
        context.environment.background.thumbnail = imgKayakShackTabletSmall;
        context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.floorTablet.showHighlight = false;
        context.environment.clickables.floorTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.clipboard.visible = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.kayak.visible = true;
        context.environment.clickables.kayak.locked = false;
        context.environment.clickables.kayak.showHighlight = true;
        context.environment.clickables.kayak.clickable = true;
        context.environment.background.src = imgKayakShack;
        context.environment.background.thumbnail = imgKayakShackSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

const KayakUnlocked = props => {
  const [user] = useContext(UserContext);
  // const ringRef = useRef();
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  user.level = props.lesson;
  user.objective = "Elkhorn Slough & Kayak Shack";
  const animateRing = useCallback(
    (context, event) => {
      console.log("start this!");
      return new Promise(resolve => {
        ringTimeLine.eventCallback("onComplete", function() {
          console.log("on complete resolving");
          resolve();
        });
        ringTimeLine.restart();
      });
    },
    [ringTimeLine]
  );
  const [current, mSend] = useMachine(envMachine, {
    services: { animateRing }
  });
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      case "pickUpTablet":
      case "waitForPage":
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={props.current.context.callImg}
          />
        );
      case "engageInCall":
        return (
          <CallWSubTitles
            {...props}
            tabletBG={current.context.tabletBG}
            videoImg={current.context.videoStill}
            current={current}
            callStep={current.context.callStep}
          />
        );
      case "ringScientist":
      default:
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={props.current.context.callImg}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment
      {...props}
      environment={current.context.environment}
      itemStored={current.context.itemStored}
      mSend={mSend}
    >
      {current.context.itemObtained && (
        <DisplayObtainedItem
          obtainable={current.context.itemObtained}
          mSend={mSend}
        />
      )}
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default KayakUnlocked;
