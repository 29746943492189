import React, { useState, useContext } from "react";
import ExplorerBadge from "./ExplorerBadge";

import fountainPenIcon from "../img/fountain_icon.png";
import largeFountainPen from "../img/large_fountain_pen.png";

import greenCheckMark from "../img/clipboard_checkmark.png";
import whiteCheckMark from "../img/check_mark.png";
import { LargeTablet } from "./UIComponents";
import { NavLink } from "react-router-dom";
import UserContext from "../context/User.js";
import SiteStates from "./SiteStates.js";
import { LabMachineContext } from "./mbariMachine";
import Image from "lqip-react";

const States = {
  testInProgress: { info: "test in progress" },
  testComplete: { info: "test complete", tabletClass: "complete" }
};

const DragAndMatchTest = props => {
  const [state, setState] = useState(States.testInProgress);
  const [userAnswers, setUserAnswers] = useState({});
  const [, fnSetUser] = useContext(UserContext);
  const [, labSend] = useContext(LabMachineContext);
  const { nextStep, objTest } = props;
  const objAnswers = props.horizontal
    ? objTest.answerKeys
    : objTest.randomAnswerKeys;

  console.log("NextStep: ", nextStep);

  const Board = props => {
    const drop = e => {
      e.preventDefault();
      e.target.classList.remove("can_drop_here");
      // const draggedCard = e.dataTransfer.getData("draggingCard");
      // console.log("Card: ", draggedCard);
      // const card = draggedCard;
      const card_id = e.dataTransfer.getData("card_id");
      const card = document.getElementById(card_id);
      const placeholderId = e.dataTransfer.getData("placeholder_id");
      const placeholder = document.getElementById(placeholderId);
      console.log("Card ID:", card_id);
      console.log("PlaceHolder ID:", placeholderId);
      console.log("Card:", card);
      placeholder.style.display = "block";
      placeholder.draggable = false;
      card.style.display = "block";
      e.target.insertBefore(card, e.target.firstChild);
      card.classList.remove("dragging");
      card.classList.add("card");
      console.log(card.parentNode);
      const origBoard = document.getElementById("board-" + card_id);
      if (props.horizontal !== true) origBoard.style.display = "none";
      // console.log(e.target.id);

      // const answer_index = e.target.id.split("-")[-1];
      // if (objTest.checkAnswer(answer_index, card_id)) {
      if (e.target.id === "answer-blank-" + card_id) {
        e.target.querySelector(".check_mark").classList.add("correct");
        objTest.isCorrect(card_id);
        userAnswers[objTest.posLookup(card_id)] = { [card_id]: true };
      } else {
        objTest.isIncorrect(card_id);
        userAnswers[objTest.posLookup(card_id)] = { [card_id]: false };
        e.target.querySelector(".check_mark").classList.remove("correct");
      }
      setUserAnswers(userAnswers);
      if (objTest.perfectScore()) {
        setState(States.testComplete);
        fnSetUser(user => ({
          ...user,
          level: 1,
          state: SiteStates.DOOR_UNLOCKED,
          next_step: "PASSED_TEST_1"
        }));
      }
      // Unhide the next test_card
      const boardQuestions = document.getElementsByClassName("board hidden");
      if (boardQuestions.length) boardQuestions[0].classList.remove("hidden");
    };
    const dragOver = e => {
      console.log("Drag Over, e:", e);
      e.preventDefault();
      e.target.classList.add("can_drop_here");
    };
    const dragLeave = e => {
      console.log("Drag Leave, e:", e);
      e.preventDefault();
      e.target.classList.remove("can_drop_here");
    };
    return (
      <div
        id={props.id}
        className={props.className}
        onDrop={drop}
        onDragOver={dragOver}
        onDragLeave={dragLeave}
      >
        {props.children}
      </div>
    );
  };

  const Card = props => {
    const dragStart = e => {
      const origCard = e.target;
      console.log("Drag Start");
      console.log(origCard);
      console.log("Parent is:", origCard.parentNode.id);
      const parentNode = origCard.parentNode;
      e.dataTransfer.setData("card_id", origCard.id);
      // alert(target.id);
      const placeHolderCard = origCard.cloneNode(true);
      placeHolderCard.id = placeHolderCard.id + "placeholder";
      e.dataTransfer.setData("placeholder_id", placeHolderCard.id);
      parentNode.appendChild(placeHolderCard);
      // e.dataTransfer.setData("draggingCard", draggingCard);
      // draggingCard.classList.add("dragging");
      // draggingCard.classList.remove("card");
      placeHolderCard.style.display = "none";
      // origCard.classList.add("hidden");

      origCard.classList.add("dragging");
      origCard.classList.remove("card");
      // origCard.id = origCard.id + "_old";

      // console.log("oldCard:", oldCard);
      console.log("card:", placeHolderCard);
      // console.log("Drop:", card_id);
      // setTimeout(() => {
      //   target.style.display = "hidden";
      // }, 0);
    };
    const dragEnd = e => {
      const target = e.target;
      //e.dataTransfer.setData("card_id", target.id);
      // alert(target.id);
      e.target.classList.add("card");
      e.target.classList.remove("dragging");
      setTimeout(() => {
        target.style.display = "normal";
      }, 0);
    };

    const dragOver = e => {
      e.stopPropagation();
    };

    return (
      <div
        id={props.id}
        className={props.className}
        draggable={props.draggable}
        onDragStart={dragStart}
        onDragOver={dragOver}
        onDragEnd={dragEnd}
        // onDrag={drag}
      >
        {props.children}
      </div>
    );
  };
  const BackgroundImage = props => {
    // return <img alt="Test background" src={background} />;
    return (
      <Image
        src={props.background}
        thumbnail={props.backgroundSmall}
        color="#132962"
        aspectRatio={"1920x1080"}
        alt="Answer blanks and questions"
      />
    );
  };

  const TestQuestion = props => {
    const board_id = "board-" + props.answerKey;
    const text = objTest.Answers[props.answerKey].text;
    const image = objTest.Answers[props.answerKey].img_src;
    const boardClasses =
      "test_question board" +
      (props.horizontal ? "" : props.index > 0 ? " hidden" : "");

    if (props.horizontal) {
      return (
        <Board id={board_id} className={boardClasses} {...props}>
          <Card id={props.answerKey} className="card" draggable="true">
            {text}
          </Card>
        </Board>
      );
    } else
      return (
        <Board id={board_id} className={boardClasses} {...props}>
          <img alt={text} src={image} />
          <div className="card_holder">
            <Card id={props.answerKey} className="card" draggable="true">
              {text}
            </Card>
          </div>
        </Board>
      );
  };

  const TestScreen = props => {
    const testQuestions = objAnswers.map((answerKey, index) => (
      <TestQuestion
        key={answerKey}
        answerKey={answerKey}
        index={index}
        {...props}
      />
    ));
    return (
      <>
        <h1>
          Drag &amp; Match
          <img alt="Fountain Pen Icon" src={fountainPenIcon} />
        </h1>

        <p>
          Drag the matching terms onto the answer sheet . <b>Good luck!</b>
        </p>
        {props.horizontal ? (
          // <Board className="test_question board">
          <img
            alt="Test Guide"
            src={props.guideImageSrc}
            className="guide_image"
          />
        ) : (
          // </Board>
          ""
        )}
        <div className="card_holder">{testQuestions}</div>
      </>
    );
  };
  const WellDoneScreen = props => {
    return (
      <div className="test_complete">
        <img alt="Large Fountain Pen" src={largeFountainPen} />
        <h1>Well Done!</h1>
        <p>
          You've performed great. These tools will come in handy right away.
        </p>
        <p> Now you're ready to proceed!</p>
        <NavLink
          className="btn-push"
          to={nextStep}
          onClick={() => labSend("PASSED_TEST_1")}
        >
          Next
        </NavLink>
      </div>
    );
  };

  const TestTablet = props => {
    const complete = state !== States.testInProgress;
    let classes = "test_tablet_container";
    classes = classes + (complete ? " complete" : "");
    console.log("Complete:", complete);
    return (
      <>
        <div className={classes}>
          {complete ? <WellDoneScreen /> : <TestScreen {...props} />}
        </div>
      </>
    );
  };

  const AnswerBoard = props => {
    const complete = state !== States.testInProgress;
    console.log("TestAnswers:", props.testAnswers);
    console.log("UserAnswers:", userAnswers);
    const checkMark = props.horizontal ? greenCheckMark : whiteCheckMark;
    const AnswerText = props => {
      const CheckMark = props => {
        return (
          <img alt="Check Mark" className={props.className} src={checkMark} />
        );
      };
      if (complete && userAnswers) {
        const uaDict = userAnswers[props.index];
        const uaAbbr = Object.keys(uaDict)[0];
        const uaCorrect = uaDict[uaAbbr];
        const imgClass = "check_mark" + (uaCorrect ? " correct" : "");

        return (
          <>
            <div id={props.answerAbbr} className="card" draggable="true">
              {objTest.AnswerText(uaAbbr)}
            </div>

            <CheckMark className={imgClass} />
          </>
        );
      } else {
        return <CheckMark className="check_mark" />;
      }
    };
    return (
      <div className="answer_sheet">
        {props.testAnswers.map((answerAbbr, index) => {
          const board_id = "answer-blank-" + answerAbbr;
          return (
            <Board
              key={answerAbbr}
              id={board_id}
              className={"board answerblank"}
              {...props}
            >
              <AnswerText index={index} answerAbbr={answerAbbr} />
            </Board>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={
        "test_container" + (props.parentClass ? " " + props.parentClass : "")
      }
    >
      <BackgroundImage {...props} />
      <AnswerBoard testAnswers={objTest.AnswerAbbrs} {...props} />

      <ExplorerBadge objective="Explore MBARI" />
      <LargeTablet showTablet={true}>
        <TestTablet objAnswers={objAnswers} {...props} />
      </LargeTablet>
    </div>
  );
};

export default DragAndMatchTest;
