import React, { useContext } from "react";
import badgeBackground from "img/badge_background.png";
import UserContext from "context/User.js";
import styled from "@emotion/styled";

// import { NONAME } from "dns";
// import explorerThumbnail from "img/explorer_thumbnail.png";

// let explorer_info = {
//   name: "Lily Wilson",
//   title: "Junior Explorer",
//   level: 1,
//   objective: "Welcome!",
//   badge_thumbnail: explorerThumbnail,
//   badge_image: ""
// };

export const TrophyExplorerBadge = props => {
  const [user] = useContext(UserContext);

  const Badge = styled.div`
    width: 27%;
    height: 20%;
    position: absolute;
    top: 9.5%;
    left: 7.2%;
    z-index: 2;
    * {
      font-size: 1vw;
      font-family: Montserrat;
      font-style: normal;
    }
    h1,
    h2,
    h3 {
      position: absolute;
      width: 55%;
      left: 36%;
    }
    h4 {
      position: absolute;
      width: 55%;
    }
  `;
  const ContainedImg = styled.img`
    width: 100%;
  `;
  const BadgeThumbnail = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 20.5%;
  `;
  const ExplorerName = styled.h1`
    position: absolute;
    font-weight: bold;
    font-size: 1.5vw;
    top: 12%;
  `;
  const ExplorerTitle = styled.h2`
    position: absolute;
    font-weight: 300;
    top: 30%;
  `;
  const ExplorerObjective = styled.h3`
    position: absolute;
    font-weight: normal;
    top: 47%;
    label {
      font-weight: bold;
    }
  `;
  const ExplorerLevel = styled.h4`
    position: absolute;
    font-size: 3vw;
    left: 8%;
    top: 33%;
  `;

  return (
    <Badge>
      <BadgeThumbnail className="explorer_thumbnail">
        <ContainedImg alt="Thumbnail" src={user.badge_thumbnail} />
      </BadgeThumbnail>
      <ExplorerName>{user.name()}</ExplorerName>
      <ExplorerTitle>{user.title}</ExplorerTitle>
      <hr
        style={{
          position: "absolute",
          width: "40%",
          left: "35%",
          top: "42%",
          zIndex: "2",
          border: ".5px solid #ffffff74",
          borderRadius: "2px"
        }}
      ></hr>
      <ExplorerObjective>
        <label>{user.objective}</label>
      </ExplorerObjective>
      <ExplorerLevel>{user.level}</ExplorerLevel>
    </Badge>
  );
};
const ExplorerBadge = props => {
  const [user] = useContext(UserContext);
  console.log("user:", user);
  // console.log("EXPLORER = ", props);

  const Badge = styled.div`
    position: absolute;
    width: 27%;
    top: -1%;
    left: -1%;

    * {
      font-size: 1vw;
      font-family: Montserrat;
      font-style: normal;
    }
    h1,
    h2,
    h3 {
      position: absolute;
      margin-block-start: 0;
      margin-block-end: 0;
      width: 100%;
      left: 41%;
    }
    h4 {
      position: absolute;
      width: 55%;
    }
    h3 {
      line-height: 1vw;
    }
  `;
  const ContainedImg = styled.img`
    width: 100%;
  `;
  const BadgeThumbnail = styled.div`
    position: absolute;
    bottom: 42.75%;
    left: 17.47%;
    width: 12%;
  `;
  const ExplorerName = styled.h1`
    position: absolute;
    font-weight: bold;
    top: 42%;
  `;
  const ExplorerTitle = styled.h2`
    position: absolute;
    font-weight: 300;
    top: 50%;
  `;
  const ExplorerObjective = styled.h3`
    position: absolute;
    font-weight: normal;

    top: 63.5%;
    label {
      float: left;
      font-weight: bold;
    }
    div {
      padding-left: 2%;
      overflow: hidden;
    }
  `;
  // const ExplorerLevel = styled.h4`
  //   position: absolute;
  //   left: 22.75%;
  //   top: 59.5%;
  // `;
  const ExplorerLevel = props => {
    return (
      <div
        style={{
          fontSize: ".8vw",
          position: "absolute",
          left: "23.5%",
          top: "67.5%"
        }}
      >
        <h1 style={{ position: "relative", left: "-50%", width: "100%" }}>
          {props.children}
        </h1>
      </div>
    );
  };

  const itemStored = props.itemStored;
  return (
    <Badge>
      <ContainedImg alt="Badge" src={badgeBackground} />
      <BadgeThumbnail className="explorer_thumbnail">
        <ContainedImg alt="Thumbnail" src={user.badge_thumbnail} />
      </BadgeThumbnail>
      <ExplorerName>{user.name()}</ExplorerName>
      <ExplorerTitle>{user.title}</ExplorerTitle>
      {props.noObjective !== true && (
        <ExplorerObjective>
          <label>Objective: </label>
          <div>{user.objective}</div>
        </ExplorerObjective>
      )}
      <ExplorerLevel>{user.level}</ExplorerLevel>
      {itemStored && (
        <div
          style={{
            position: "absolute",
            width: "15%",
            left: "94%",
            top: "33%"
          }}
        >
          <img
            style={{ width: "100%" }}
            src={itemStored.src}
            alt={itemStored.title}
          />
        </div>
      )}
    </Badge>
  );
};

export default ExplorerBadge;
