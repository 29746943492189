import React, { useEffect, useRef, useState, useContext } from "react";
import FaArrowCircleRight from "react-icons/lib/fa/arrow-circle-right";
import FaArrowCircleLeft from "react-icons/lib/fa/arrow-circle-left";
import testTubeIcon from "img/test_tube_icon.png";
import droneVideo from "img/drone_video_example.png";
import playIcon from "img/play_icon.png";
import tabletBG from "img/tablet_background.jpg";
import cropRings from "img/crop_rings.svg";
import questImg from "img/quest_ocean_discovery.svg";
import btnQuestApp from "img/quest_app_button.png";
import btnVideoChat from "img/video_chat_button.png";
import btnNotes from "img/notes_button.png";
import btnAskScientist from "img/ask_scientist_button.svg";
import imgNotesIntro1 from "img/notes_intro_img_1.png";
import imgNotesIntro2 from "img/notes_intro_img_2.png";
import iconIntroFolder from "img/notes_intro_folder.png";
import imgMbariNotesFolder from "img/notes_auv_folder.png";
// import videoImg from "img/emery_nolasco_call.png";

import newLevelPhotoBG from "img/new_level_photo_bg.png";
import profilePicture from "img/lily_wilson_profile.png";
import awardTabletBG from "img/outro_award_bg.jpg";

import ContentEditable from "react-contenteditable";
import { ButtonCenter, AppButton } from "components/Tablet";

import gsap from "gsap/dist/gsap";
import { TimelineLite, CSSPlugin } from "gsap";
import UserContext from "context/User";
import YouTube from "react-youtube";

//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(CSSPlugin);

const SlideButton = props => {
  return (
    <div
      style={{
        position: "absolute",
        height: "6%",
        width: "38%",
        bottom: "10%",
        left: "30%",
        backgroundColor: "#ffffff7f",
        borderRadius: "999px"
      }}
    >
      <h1
        style={{
          position: "absolute",
          top: "25%",
          right: "15%",
          fontSize: "1vw",
          fontWeight: "bold",
          textTransform: "uppercase"
        }}
      >
        Slide to Answer
      </h1>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "17%",
          left: "0px",
          top: "0px",
          backgroundColor: "#FFD702",
          borderRadius: "999px"
        }}
        onClick={() => props.mSend("SLIDE")}
      >
        <div
          style={{
            position: "absolute",
            left: "24%",
            top: "47%",
            backgroundColor: "#052c94",
            width: "52%",
            height: "6%"
          }}
        />
        <div
          style={{
            position: "absolute",
            left: "58.5%",
            top: "41%",
            backgroundColor: "#052c94",
            width: "22%",
            height: "6%",
            transform: "rotate(45deg)"
          }}
        />
        <div
          style={{
            position: "absolute",
            left: "58.5%",
            top: "52.9%",
            backgroundColor: "#052c94",
            width: "22%",
            height: "6%",
            transform: "rotate(135deg)"
          }}
        />
      </div>
    </div>
  );
};
export const IconInstruction = props => {
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="icon_instructions">
        <img alt="Kayak" src={props.icon} />
        <h1>{props.title}</h1>
        <p>{props.message}</p>
        <AppButton {...{ onClick: () => props.mSend("GO") }}>Go!</AppButton>
      </div>
    </div>
  );
};
const VideoScreen = props => {
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1
    }
  };
  const videoId = props.videoId ? props.videoId : "jwt_JU3lhSo";
  const title = props.videoTitle ? props.videoTitle : "Welcome to MBARI";
  const introduction = props.videoIntroduction
    ? props.videoIntroduction
    : "In this lesson, you will learn the basics of aquarium research and tools we are using here at MBARI.";
  const videoCaption = props.videoCaption
    ? props.videoCaption
    : "Have you ever used a drone? If you have, you will probably be good at using some of our vehicles...";
  const buttonLabel = props.buttonLabel ? props.buttonLabel : "Got it";
  const buttonAction = props.buttonAction ? props.buttonAction : "CLICK";
  const className = props.className ? props.className : "tablet_video";
  const onEnd = props.onEnd ? props.onEnd : null;
  const finishButton = props.noButton ? null : (
    <ButtonCenter>
      <AppButton {...{ onClick: () => props.mSend(buttonAction) }}>
        {buttonLabel}
      </AppButton>
    </ButtonCenter>
  );
  return (
    <>
      <h1>
        {title}
        <img alt="Fountain Pen Icon" src={testTubeIcon} />
      </h1>

      <p>{introduction}</p>
      <div className="video_screen">
        {/* <img alt="Underwater Drone" src={droneVideo} /> */}
        <YouTube
          videoId={videoId} // defaults -> null
          // id={string} // defaults -> null
          className={className} // defaults -> null
          // containerClassName={string} // defaults -> ''
          opts={opts} // defaults -> {}
          // onReady={func} // defaults -> noop
          // onPlay={func} // defaults -> noop
          // onPause={func} // defaults -> noop
          onEnd={onEnd} // defaults -> noop
          // onError={func} // defaults -> noop
          // onStateChange={func} // defaults -> noop
          // onPlaybackRateChange={func} // defaults -> noop
          // onPlaybackQualityChange={func} // defaults -> noop
        />
      </div>
      <p>{videoCaption}</p>
      {finishButton}
    </>
  );
};
export const ShowVideo = props => {
  const classes = "video_tablet_container";
  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className={classes}>
        <VideoScreen {...props} />
      </div>
      {/* <div className="play_icon">
        <img alt="play" src={playIcon} />
      </div>
      <div className="play_button" /> */}
    </>
  );
};

export const LessonPage = props => {
  const classes = "video_tablet_container";
  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className={classes}>
        <h1>
          {props.title}
          <img alt="Fountain Pen Icon" src={testTubeIcon} />
        </h1>

        <p>{props.introduction}</p>
        <div className="video_screen">
          <img alt={props.videoStillAlt} src={props.videoStill} />
        </div>
        <p>{props.videoCaption}</p>
        <ButtonCenter>
          <AppButton {...{ onClick: () => props.mSend(props.buttonAction) }}>
            {props.buttonText}
          </AppButton>
        </ButtonCenter>
      </div>
      <div className="play_icon">
        <img alt="play" src={playIcon} />
      </div>
      <div className="play_button" />
    </>
  );
};

const OneSample = props => {
  return (
    <div className="wt_sample">
      <div
        className="wt_sample_marker"
        style={{
          backgroundColor: props.sample.color,
          borderColor: props.sample.color
        }}
      />
      <div className="wt_marker_label">{props.sample.marker}</div>
      <div className="wt_sample_text">{props.sample.name}</div>
    </div>
  );
};

export const TestingPage = props => {
  console.log("props.samples:", props.samples);
  const arrSamples = props.samples
    ? props.samples
    : [{ color: "blank", marker: "?", name: "No samples found" }];

  console.log("objSamples:", arrSamples);

  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className="video_tablet_container">
        <h1>
          {props.title}
          <img alt="Fountain Pen Icon" src={testTubeIcon} />
        </h1>
        <div className="wt_sample_list">
          {arrSamples.map((sample, id) => (
            <OneSample sample={sample} />
          ))}
          <h4>{props.instructions}</h4>
        </div>

        <ButtonCenter>
          <AppButton {...{ onClick: () => props.mSend(props.buttonAction) }}>
            {props.buttonText}
          </AppButton>
        </ButtonCenter>
      </div>
    </>
  );
};
export const ManualInitiateCall = props => {
  const ringRef = useRef();
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  useEffect(() => {
    // console.log("INITIALIZING!");
    // const ringTimeLine = new TimelineLite({ paused: false });
    ringTimeLine
      .from(ringRef.current, 0.1, { opacity: 1 })
      .to(ringRef.current, 0.1, {
        opacity: 0
      })
      .yoyo(true)
      .repeat(5);
    // ringTimeLine.restart();
  });
  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
        <div className="incall">
          <h1>Now Calling</h1>
          <div className="calling_img_crop">
            <img
              onClick={() => props.mSend("CLICK")}
              alt="Large Tablet - Call Scientist"
              src={props.callImg}
            />
          </div>
          <div className="as_crop_circles" ref={ringRef}>
            <img alt="Call coming in!" src={cropRings} />
          </div>

          <div className="as_text_box">
            <h1>{props.current.context.scientistName}</h1>
            <h2>({props.current.context.scientistTitle})</h2>
          </div>
          <SlideButton text="Slide to Pick Up" mSend={props.mSend} />
        </div>
      </div>
    </>
  );
};
export const NewManualInitiateCall = props => {
  const ringRef = useRef();
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  useEffect(() => {
    // console.log("INITIALIZING!");
    // const ringTimeLine = new TimelineLite({ paused: false });
    ringTimeLine
      .from(ringRef.current, 0.1, { opacity: 1 })
      .to(ringRef.current, 0.1, {
        opacity: 0
      })
      .yoyo(true)
      .repeat(5);
    // ringTimeLine.restart();
  });
  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
        <div className="incall">
          <h1>Now Calling</h1>
          <div className="calling_img_crop">
            <img
              onClick={() => props.mSend("CLICK")}
              alt="Large Tablet - Call Scientist"
              src={props.callImg}
            />
          </div>
          <div className="as_crop_circles" ref={ringRef}>
            <img alt="Call coming in!" src={cropRings} />
          </div>

          <div className="as_text_box">
            <h1>{props.current.context.scientistName}</h1>
            <h2>({props.current.context.scientistTitle})</h2>
          </div>
          <SlideButton text="Slide to Pick Up" mSend={props.mSend} />
        </div>
      </div>
    </>
  );
};
export const AutoInitiateCall = props => {
  const ringRef = useRef();
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  useEffect(() => {
    console.log("INITIALIZING!");
    // const ringTimeLine = new TimelineLite({ paused: false });
    ringTimeLine
      .from(ringRef.current, 0.1, { opacity: 1 })
      .to(ringRef.current, 0.1, {
        opacity: 0
      })
      .yoyo(true)
      .repeat(5);
    // ringTimeLine.restart();
  });
  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className="incall">
        <div className="calling_img_crop">
          <img
            onClick={() => props.mSend("CLICK")}
            alt="Large Tablet - Call Scientist"
            src={props.callImg}
          />
        </div>
        <div className="as_crop_circles" ref={ringRef}>
          <img alt="Call coming in!" src={cropRings} />
        </div>

        <div className="as_text_box">
          <h1>{props.current.context.scientistName}</h1>
          <h2>({props.current.context.scientistTitle})</h2>
          <SlideButton text="Slide to Pick Up" />
        </div>
      </div>
    </>
  );
};

export const CallWSubTitles = props => {
  const { mSend, videoImg, current, callStep } = props;
  const [user] = useContext(UserContext);
  const handleUserKeyPress = event => {
    const { keyCode } = event;

    if (keyCode === 32 && event.target === document.body) {
      mSend("FORWARD");
    }
    if (keyCode === 8 && event.target === document.body) {
      mSend("BACK");
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyPress);
    return () => {
      window.removeEventListener("keyup", handleUserKeyPress);
    };
  });
  const objReplacements = {};
  if (user.firstName) objReplacements["FIRSTNAME"] = user.firstName;
  if (current.context.scientistName)
    objReplacements["SCIENTISTNAME"] = current.context.scientistName;
  if (current.context.scientistTitleShort)
    objReplacements["SCIENTISTTITLE"] = current.context.scientistTitleShort;
  console.log(objReplacements);
  const re = new RegExp(Object.keys(objReplacements).join("|"), "g");
  console.log(re);
  const thisSubTitle = current.context.scientistSubTitles[callStep].replace(
    re,
    function(matched) {
      return objReplacements[matched];
    }
  );

  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className="incall">
        <div className="as_video">
          <img src={videoImg} alt={current.context.scientistName} />
        </div>
        <div className="incall_text_box">
          <div className="as_video_overlay_text">
            <h1>{current.context.scientistName}</h1>
            <h2>({current.context.scientistTitle})</h2>
          </div>
          <div className="as_text">
            <p>{thisSubTitle}</p>
            <div className="incall_navigation">
              <FaArrowCircleLeft
                className={callStep === 0 ? "noclick" : null}
                onClick={callStep !== 0 ? () => mSend("BACK") : null}
              />
              <FaArrowCircleRight onClick={() => mSend("FORWARD")} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const NewCallWSubTitles = props => {
  const { mSend, videoImg, current, callStep } = props;
  const [user] = useContext(UserContext);
  const handleUserKeyPress = event => {
    const { keyCode } = event;

    if (keyCode === 32 && event.target === document.body) {
      mSend("FORWARD");
    }
    if (keyCode === 8 && event.target === document.body) {
      mSend("BACK");
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyPress);
    return () => {
      window.removeEventListener("keyup", handleUserKeyPress);
    };
  });
  const objReplacements = {};
  if (user.firstName) objReplacements["FIRSTNAME"] = user.firstName;
  if (current.context.scientistName)
    objReplacements["SCIENTISTNAME"] = current.context.scientistName;
  if (current.context.scientistTitleShort)
    objReplacements["SCIENTISTTITLE"] = current.context.scientistTitleShort;
  console.log(objReplacements);
  const re = new RegExp(Object.keys(objReplacements).join("|"), "g");
  console.log(re);
  const thisSubTitle = current.context.scientistSubTitles[callStep].replace(
    re,
    function(matched) {
      return objReplacements[matched];
    }
  );

  return (
    <>
      <div className="as-bg">
        <img alt="Large Tablet - Screen" src={tabletBG} />
      </div>
      <div className="incall">
        <div className="as_video">
          <img src={videoImg} alt={current.context.scientistName} />
        </div>
        <div className="incall_text_box">
          <div className="as_video_overlay_text">
            <h1>{current.context.scientistName}</h1>
            <h2>({current.context.scientistTitle})</h2>
          </div>
          <div className="as_text">
            <p>{thisSubTitle}</p>
            <div className="incall_navigation">
              <FaArrowCircleLeft
                className={callStep === 0 ? "noclick" : null}
                onClick={callStep !== 0 ? () => mSend("BACK") : null}
              />
              <FaArrowCircleRight onClick={() => mSend("FORWARD")} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AppBGScreen = props => {
  console.log(props);
  return (
    <div className="nas-screen" {...props}>
      {props.children}
    </div>
  );
};

export const AppScreen = props => {
  const { mSend } = props;
  const arrButtons = [
    { srcImg: btnQuestApp, label: "QuestApp" },
    { srcImg: btnVideoChat, label: "VideoChat" },
    {
      srcImg: btnNotes,
      label: "Notes",
      clickable: props.focus === "Notes",
      onClick: () => mSend("NOTES")
    },
    {
      srcImg: btnAskScientist,
      label: "AskScientist",
      clickable: props.focus === "AskScientist",
      onClick: () => mSend("ASK")
    }
  ];
  return (
    <AppBGScreen>
      <div className="nas-logo">
        <img src={questImg} alt="Quest" />
      </div>
      <div className="nas-app-buttons">
        {arrButtons.map(({ srcImg, label, clickable, onClick }, id) => (
          <div
            key={id}
            className={"nas-app-button" + (clickable ? " nclickable" : "")}
            onClick={onClick ? onClick : null}
          >
            <img src={srcImg} alt={label} />
            <h4>{label}</h4>
          </div>
        ))}
      </div>
    </AppBGScreen>
  );
};

const NavBar = props => {
  const { mSend, current } = props;
  const { backClickable, parentDir, currentFile } = current.context;
  return (
    <div className="n_navbar">
      <div
        className={"back" + (backClickable === false ? " n_no_click" : "")}
        onClick={backClickable ? () => mSend("BACK") : null}
      >
        &lt; Back
      </div>
      <div className="breadcrumb">
        {parentDir && (
          <>
            <div className="root" onClick={() => mSend("NOTES")}>
              {parentDir}
            </div>
            &gt;
          </>
        )}
        <div className="current">{currentFile}</div>
      </div>
    </div>
  );
};

export const Intro = props => {
  const { mSend, current } = props;
  const { page, backArrowClickable, forwardArrowClickable } = current.context;

  return (
    <AppBGScreen>
      <NavBar {...props} />
      <div className="n_info">
        <h1>Introduction</h1>
        <h2>Hello, fellow explorer...</h2>
        <p>Welcome to your Science and Engineering Notebook!</p>
        <p>
          Famous scientists like Edison, Galileo and Einstein have used
          notebooks to record their ideas, which changed our world. It's
          important for you, too, to write down what you discover and learn
          about science!
        </p>
        <figure>
          <img
            src={imgNotesIntro1}
            className="first_img"
            alt="Galileo Galileis Sunspot Drawings"
          />
          <figcaption>Galileo Galileis Sunspot Drawings</figcaption>
        </figure>
        <figure>
          <img
            src={imgNotesIntro2}
            className="second_img"
            alt="Albert Enstein Notebook"
          />
          <figcaption>Albert Enstein Notebook (below)</figcaption>
        </figure>
        <div className="n_bottom_nav">
          <div
            className={
              "n_back_arrow" +
              (backArrowClickable === false ? " n_no_click" : "")
            }
            onClick={() => mSend("BACKARROW")}
          >
            &lt;
          </div>
          {page}
          <div
            className={
              "n_forward_arrow" +
              (forwardArrowClickable === false ? " n_no_click" : "")
            }
            onClick={() => mSend("FORWARDARROW")}
          >
            &gt;
          </div>
        </div>
      </div>
    </AppBGScreen>
  );
};

const initialNotes = {
  MBARI:
    "Despite practicing navigating the AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed! Despite I practiced navigating AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed! Despite I practiced navigating AUV (which are pretty cool) i find ROVs hilarious! Like drones indeed!",
  ELKHORN: ""
};

export const Reflection = props => {
  const { mSend, current } = props;
  const notesID = props.notesID || "MBARI";
  const [state, setState] = useState([initialNotes[notesID], "", ""]);

  const handleInput = (event, id) => {
    console.log("state=", state);
    console.log("id=", id);

    console.log("event=", event);
    let newState = [...state];
    newState[id - 1] = event.target.value;
    setState(newState);
  };

  const objQuestions = {
    MBARI: [
      {
        id: 1,
        text: "Which tool is your favorite and why?",
        image: imgMbariNotesFolder,
        alt: "MBARI Building"
      },
      { id: 2, text: "What did you learn about coding?" },
      {
        id: 3,
        text:
          "What adjustments did you make to your program to successfully collect the targets?"
      }
    ],
    ELKHORN: [
      {
        id: 1,
        text:
          "Which was your favorite organism along your kayaking journey and why?",
        image: true,
        alt: "Click here to upload your favorite photo from your journey.",
        subTitle: "Click here to upload your favorite photo from your journey."
      },
      {
        id: 2,
        text: "Do you think estuaries are important? Prove your point."
      },
      {
        id: 3,
        text: "Do you have any ideas of how we can help conserve our estuaries?"
      }
    ]
  };

  return (
    <AppBGScreen>
      <NavBar {...props} />

      <div className="n_reflect">
        <div className="n_file_info">
          <div>Draft Saved</div>
          <div>17:05</div>
        </div>
        <h1>MBARI Reflection</h1>
        <div className="n_questions">
          {objQuestions[notesID].map((question, id) => {
            return (
              <div key={id}>
                <div>
                  {question.id}. {question.text}
                  {question.image && (
                    <figure className="notes_figure">
                      <img
                        src={
                          current.context.savedImage
                            ? current.context.savedImage
                            : question.image
                        }
                        alt={question.alt}
                        className="inset_clickable"
                        onClick={() => mSend("IMAGECHOOSE")}
                      />
                      <figcaption>{question.subTitle}</figcaption>
                    </figure>
                  )}
                </div>
                <ContentEditable
                  html={state[question.id - 1]} // innerHTML of the editable div
                  disabled={false} // use true to disable editing
                  onChange={e => handleInput(e, question.id)} // handle innerHTML change
                  tagName="p" // Use a custom HTML tag (uses a div by default)
                  className="n_answer n_saved"
                ></ContentEditable>
              </div>
            );
          })}
        </div>
        <div className="button_holder">
          <button className="app_button" onClick={() => mSend("SAVE")}>
            Save
          </button>
        </div>
      </div>
    </AppBGScreen>
  );
};

export const GallerySelection = props => {
  const { mSend, images, current } = props;
  const notesID = props.notesID || "MBARI";
  const [state, setState] = useState([initialNotes[notesID], "", ""]);

  const handleInput = (event, id) => {
    console.log("state=", state);
    console.log("id=", id);

    console.log("event=", event);
    let newState = [...state];
    newState[id - 1] = event.target.value;
    setState(newState);
  };

  return (
    <AppBGScreen>
      <NavBar {...props} />

      <div className="n_reflect">
        {images.map((objImage, id) => (
          <figure
            className="kg_gallery_image"
            onClick={() => mSend("SELECT", { selection: objImage })}
            key={id}
          >
            <img
              src={objImage.src}
              alt={
                "Saved Image from Kayak Collection Mission, taken at " +
                objImage.time
              }
            />
            <figcaption>
              <div className="row">
                <div className="left">Time:</div>
                <div className="right">{objImage.time}</div>
              </div>
              <div className="row">
                <div className="left">Source:</div>
                <div className="right">{objImage.source}</div>
              </div>
            </figcaption>
          </figure>
        ))}
      </div>
    </AppBGScreen>
  );
};

export const NotesSelection = props => {
  const { mSend, current } = props;
  const objFolders = {
    1: (
      <div className="n_folder" onClick={() => mSend("MBARIREFLECT")} key={1}>
        <figure>
          <img src={imgMbariNotesFolder} alt="MBARI Notebook" />
          <figcaption>
            <h3>Draft</h3>
            <h1>MBARI Reflection</h1>
            <h2>Which tool is your favorite...</h2>
          </figcaption>
        </figure>
      </div>
    ),
    2: (
      <div className="n_folder" onClick={() => mSend("ELKHORNREFLECT")} key={2}>
        <figure>
          <img src={current.context.savedImage} alt="Elkhorn Slough Notebook" />
          <figcaption>
            <h3>Draft</h3>
            <h1>Elkhorn Slough Reflection</h1>
            <h2>My favorite organism along...</h2>
          </figcaption>
        </figure>
      </div>
    )
  };
  let arrFolders = [];
  const lessonCount = props.lesson || 1;
  console.log("lessonCount: ", lessonCount);
  for (let i = 1; i <= lessonCount; i++) {
    arrFolders.push(objFolders[i]);
  }
  return (
    <AppBGScreen>
      <NavBar {...props} />

      <div className="n_folders">
        <div className="n_folder" onClick={() => mSend("INTRO")}>
          <figure>
            <img
              src={iconIntroFolder}
              className="Foreword"
              alt="Introduction"
            />
            <figcaption>
              <h3>01/01/2020</h3>
              <h1>Introduction</h1>
              <h2>Hello, fellow explorer...</h2>
            </figcaption>
          </figure>
        </div>
        {arrFolders}
        <div className="button_holder">
          <button className="app_button" onClick={() => mSend("DONE")}>
            Return to Lab
          </button>
        </div>
      </div>
    </AppBGScreen>
  );
};
export const AwardPageNewLevel = props => {
  const { mSend, level } = props;
  const ringRef = useRef();
  const bgStyle = {
    backgroundImage: "url(" + awardTabletBG + ")"
  };
  return (
    <AppBGScreen style={bgStyle}>
      <h1 className="nl_title">New Level</h1>
      <div className="nl_highlight">
        <div className="nl_crop_circles" ref={ringRef}>
          <img alt="Award!" src={cropRings} />
        </div>
        <div className="nl_medal">
          <img src={newLevelPhotoBG} alt="Medal" />
        </div>
        <div className="nl_profile">
          <img src={profilePicture} alt="You" />
        </div>
        <div className="nl_level">{level}</div>
      </div>
      <div className="button_holder">
        <button className="app_button_blue" onClick={() => mSend("CONTINUE")}>
          Continue
        </button>
      </div>
    </AppBGScreen>
  );
};

export const AwardPageRewards = props => {
  const { onClick, arrBadges, title } = props;
  const ringRef = useRef();

  const bgStyle = {
    backgroundImage: "url(" + awardTabletBG + ")"
  };
  return (
    <AppBGScreen style={bgStyle}>
      <h1 className="nl_title">{title}: Cleared</h1>
      <h2 className="nl_subtitle">Rewards</h2>
      <div className="badges">
        {arrBadges.map((badge, index) => {
          return (
            <div className="reward_badge" key={index}>
              <div className="badge_crop_circles" ref={ringRef}>
                <img alt="Highlight" src={cropRings} />
              </div>
              <figure>
                <img
                  alt={badge.name + " " + badge.type}
                  src={badge.img}
                  style={badge.style}
                />
                <figcaption>
                  <h1>{badge.name}</h1>
                  <h2>{badge.type}</h2>
                </figcaption>
              </figure>
            </div>
          );
        })}
      </div>
      <div className="button_holder">
        <button className="app_button_blue" onClick={onClick}>
          Claim
        </button>
      </div>
    </AppBGScreen>
  );
};
export const AskScientist = props => {
  const ringRef = useRef();
  const { current } = props;

  useEffect(() => {
    console.log("INITIALIZING!");
    // const ringTimeLine = new TimelineLite({ paused: false });
    props.ringTimeLine
      .from(ringRef.current, 0.1, { opacity: 1 })
      .to(ringRef.current, 0.1, {
        opacity: 0
      })
      .yoyo(true)
      .repeat(5);
    // ringTimeLine.restart();
  });
  console.log("RUNNING!");
  return (
    <div className="as-bg">
      <img alt="Large Tablet - Screen" src={tabletBG} />
      <div className="incall">
        <div className="calling_img_crop">
          <img
            onClick={() => props.mSend("CLICK")}
            alt="Large Tablet - Call Scientist"
            src={current.context.callImg}
          />
        </div>
        <div className="as_crop_circles" ref={ringRef}>
          <img alt="Call coming in!" src={cropRings} />
        </div>

        <div className="as_text_box">
          <h1>{current.context.scientistName}</h1>
          <h2>({current.context.scientistTitle})</h2>
        </div>
      </div>
    </div>
  );
};
