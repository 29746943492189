import React, { useState, useCallback, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet, DisplayObtainedItem } from "components/UIComponents";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import imgKayakShack from "img/ks_shack_background.jpg";
import imgKayakShackSmall from "img/ks_shack_background_sm.jpg";
import imgKayakShackTablet from "img/ks_shack_background_tablet.jpg";
import imgKayakShackTabletSmall from "img/ks_shack_background_tablet_sm.jpg";
import imgClipboardHighlight from "img/ks_shack_clipboard_highlight.svg";
// import imgOnFloorTablet from "img/ks_shack_charles_nye_tablet.png";
import imgFloorTabletHighlight from "img/ks_shack_floor_tablet_highlight.svg";
import imgLockeKayak from "img/ks_shack_locked_kayak.svg";
// import FaArrowCircleRight from "react-icons/lib/fa/arrow-circle-right";
// import FaArrowCircleLeft from "react-icons/lib/fa/arrow-circle-left";
import tabletBG from "img/tablet_background.jpg";
// import callImg from "img/charles_nye_calling.png";
// import cropRings from "img/crop_rings.svg";

// import callWithScientist from "img/call_with_charles_nye.png";
import gsap from "gsap/dist/gsap";
import { TimelineLite, CSSPlugin } from "gsap";
import UserContext from "context/User";

import { CallWSubTitles, ManualInitiateCall } from "components/TabletApps";
import appData from "components/appCall/CallingCharlesNye";
// import Lesson from "components/Lesson1/lesson";
//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(CSSPlugin);

function subTwoPercentages(one, two) {
  return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
}
export const envMachine = Machine(
  {
    id: "kayakShack",
    context: {
      environment: {
        className: "env_shack",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: true,
          src: imgKayakShack,
          thumbnail: imgKayakShackSmall,
          alt: "Elkorn Slough Kayak Shack",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          floorTablet: {
            visible: true,
            clickable: true,
            showHighlight: true,
            showHover: true,
            ringing: true,
            action: "pickUpTablet",
            className: "floor_tablet",
            // style: {
            //   position: "absolute",
            //   width: "30%",
            //   left: "9%",
            //   bottom: "0"
            // },
            shiftedStyle: {},
            layers: [
              {
                name: "overlay",
                optimize: false,
                src: appData.imgOnFloorTablet,
                style: {
                  position: "absolute",
                  left: "46.2%",
                  width: "11%",
                  bottom: "10%"
                },
                aspectRatio: "179x211"
              },
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesHover: true,
                handlesRing: true,
                optimize: false,
                src: imgFloorTabletHighlight,
                style: {
                  position: "absolute",
                  left: "45.5%",
                  width: "12%",
                  bottom: "8%"
                },
                aspectRatio: "179x211"
              }
            ]
          },
          clipboard: {
            visible: true,
            clickable: false,
            showHighlight: false,
            showHover: true,
            ringing: false,
            action: "TOUCH",
            className: "clipboard",
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,
                handlesRing: true,
                optimize: false,
                src: imgClipboardHighlight,
                handlesHover: true,
                style: {
                  position: "absolute",
                  left: "46%",
                  width: "18%",
                  top: "73.0%"
                },
                aspectRatio: "113x126"
              }
            ]
          },
          kayak: {
            visible: true,
            clickable: true,
            ringing: false,
            showHighlight: true,
            action: "ENTER",
            className: "locked_kayak",

            shiftedStyle: {},
            layers: [
              {
                name: "highlight",
                handlesClick: true,
                handlesHighlight: true,

                optimize: false,
                src: imgLockeKayak,
                style: {
                  width: "100%"
                }
              }
            ]
          }
        }
      },
      callStep: 0,
      callImg: appData.callImg,
      tabletBG: tabletBG,
      scientistName: appData.scientistName,
      scientistTitle: appData.scientistTitle,
      videoStill: appData.callWithScientist,
      scientistSubTitles: [
        "“Hey, you’ve made It to the shack! Cool! See that clipboard on the table? There is some important data you should learn before further slough exploration...”"
      ],
      showTablet: false,
      itemObtained: null
    },
    initial: "shackScene",
    states: {
      initialize: {
        on: {
          "": {
            target: "waitForDeckScene",
            actions: ["createShiftedStyle"]
          }
        }
      },
      waitForDeckScene: {
        after: {
          100: "shackScene"
        }
      },
      shackScene: {
        on: {
          pickUpTablet: {
            target: "pickUpTablet",
            actions: ["pickUpTablet"]
          },
          TOUCH: {
            target: "done",
            actions: assign({ done: true })
          }
        }
      },
      waitForPage: {
        after: {
          100: "startCall"
        }
      },
      pickUpTablet: {
        on: {
          SLIDE: {
            target: "engageInCall",
            actions: assign({
              callConnected: true
            })
          }
        }
      },
      startCall: {
        invoke: {
          src: "animateRing",
          onDone: {
            target: "done",
            // target: "engageInCall",
            actions: [assign({})]
          }
        }
      },
      engageInCall: {
        on: {
          FORWARD: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep + 1 < context.scientistSubTitles.length;
              },
              actions: [
                assign({
                  callStep: context => context.callStep + 1,
                  callInProgress: true
                })
              ]
            },
            {
              target: "shackScene",
              actions: "finishCall"
            }
          ],
          BACK: [
            {
              target: "engageInCall",
              cond: (context, event) => {
                return context.callStep - 1 >= 0;
              },
              actions: assign({
                callStep: context => context.callStep - 1,
                callInProgress: true
              })
            },
            {
              actions: assign({
                callInProgress: true
              })
            }
          ]
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      createShiftedStyle: (context, event) => {
        // console.log("HERE!");
        Object.keys(context.environment.clickables).forEach(function(key) {
          const clickable = context.environment.clickables[key];
          console.log(clickable);
          clickable.shiftedStyle = {};
          Object.assign(clickable.shiftedStyle, { ...clickable.style });
          clickable.shiftedStyle.left = subTwoPercentages(
            clickable.style.left,
            context.environment.shiftLeft
          );
          // console.log("HERE! shiftedStyle = ", clickable.shiftedStyle);
          // console.log("HERE! style = ", clickable.style);
        });
      },
      storeItem: (context, event) => {
        context.itemStored = context.itemObtained;
        context.itemObtained = null;
        context.environment.clickables.clipboard.showHighlight = true;
        context.environment.clickables.clipboard.visible = true;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakShackTablet;
        context.environment.background.thumbnail = imgKayakShackTabletSmall;
        context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.floorTablet.showHighlight = false;
        context.environment.clickables.floorTablet.visible = false;
        context.environment.clickables.floorTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.clipboard.visible = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.clipboard.showHighlight = true;
        context.environment.clickables.clipboard.ringing = true;

        context.environment.background.src = imgKayakShack;
        context.environment.background.thumbnail = imgKayakShackSmall;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

const KayakShack = props => {
  const [user] = useContext(UserContext);
  // const ringRef = useRef();
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  user.level = props.lesson;
  user.objective = "Elkhorn Slough & Kayak Shack";
  const animateRing = useCallback(
    (context, event) => {
      console.log("start this!");
      return new Promise(resolve => {
        ringTimeLine.eventCallback("onComplete", function() {
          console.log("on complete resolving");
          resolve();
        });
        ringTimeLine.restart();
      });
    },
    [ringTimeLine]
  );
  const [current, mSend] = useMachine(envMachine, {
    services: { animateRing }
  });
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  // const RingScientist = props => {
  //   const { current } = props;
  //   useEffect(() => {
  //     console.log("INITIALIZING!");
  //     // const ringTimeLine = new TimelineLite({ paused: false });
  //     ringTimeLine
  //       .from(ringRef.current, 0.1, { opacity: 1 })
  //       .to(ringRef.current, 0.1, {
  //         opacity: 0
  //       })
  //       .yoyo(true)
  //       .repeat(5);
  //     // ringTimeLine.restart();
  //   });
  //   // console.log("RUNNING!");
  //   return (
  //     <>
  //       <div className="as-bg">
  //         <img alt="Large Tablet - Screen" src={tabletBG} />
  //       </div>
  //       <div className="incall">
  //         <div className="calling_img_crop">
  //           <img
  //             onClick={() => mSend("CLICK")}
  //             alt="Large Tablet - Call Scientist"
  //             src={callImg}
  //           />
  //         </div>
  //         <div className="as_crop_circles" ref={ringRef}>
  //           <img alt="Call coming in!" src={cropRings} />
  //         </div>

  //         <div className="as_text_box">
  //           <h1>{current.context.scientistName}</h1>
  //           <h2>({current.context.scientistTitle})</h2>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };
  // const OnCall = props => {
  //   const { mSend, tabletBG, videoImg, current, callStep } = props;
  //   const handleUserKeyPress = event => {
  //     const { keyCode } = event;

  //     if (keyCode === 32 && event.target === document.body) {
  //       mSend("FORWARD");
  //     }
  //     if (keyCode === 8 && event.target === document.body) {
  //       mSend("BACK");
  //     }
  //   };

  //   useEffect(() => {
  //     window.addEventListener("keyup", handleUserKeyPress);
  //     return () => {
  //       window.removeEventListener("keyup", handleUserKeyPress);
  //     };
  //   });

  //   return (
  //     <>
  //       <div className="as-bg">
  //         <img alt="Large Tablet - Screen" src={tabletBG} />
  //       </div>
  //       <div className="incall">
  //         <div className="as_video">
  //           <img src={videoImg} alt={current.context.scientistName} />
  //         </div>
  //         <div className="incall_text_box">
  //           <div className="as_video_overlay_text">
  //             <h1>{current.context.scientistName}</h1>
  //             <h2>({current.context.scientistTitle})</h2>
  //           </div>
  //           <div className="as_text">
  //             <p>{current.context.scientistSubTitles[callStep]}</p>
  //             <div className="incall_navigation">
  //               <FaArrowCircleLeft
  //                 className={callStep === 0 ? "noclick" : null}
  //                 onClick={callStep !== 0 ? () => mSend("BACK") : null}
  //               />
  //               <FaArrowCircleRight onClick={() => mSend("FORWARD")} />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      case "pickUpTablet":
      case "waitForPage":
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={props.current.context.callImg}
          />
        );
      case "engageInCall":
        return (
          <CallWSubTitles
            {...props}
            tabletBG={current.context.tabletBG}
            videoImg={current.context.videoStill}
            current={current}
            callStep={current.context.callStep}
          />
        );
      case "ringScientist":
      default:
        return (
          <ManualInitiateCall
            {...props}
            mSend={props.mSend}
            callImg={props.current.context.callImg}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment
      {...props}
      environment={current.context.environment}
      itemStored={current.context.itemStored}
      mSend={mSend}
    >
      {current.context.itemObtained && (
        <DisplayObtainedItem
          obtainable={current.context.itemObtained}
          mSend={mSend}
        />
      )}
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default KayakShack;
