import React from "react";
import dtBlastQuiz from "img/dt_blast_quiz.png";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Lab from "components/Lab";

export const labMachine = Machine({
  id: "lab_in_between",
  context: {
    tabletClickable: true,
    tabletRinging: true,
    chalkboardGlows: false,
    chalkboardClickable: false,
    labDoorIsUnlocked: false,
    showTablet: false,
    callInProgress: false,
    callStep: 0,
    tabletApp: "",
    calling: true,
    // tabletOverlay: true,
    deskTabletOverlay: true
  },
  initial: "inBetween",
  states: {
    inBetween: {
      on: {
        CLICK: {
          target: "appBlastQuiz",
          actions: assign({
            showTablet: true,
            tabletRinging: false,
            tabletApp: "appBlastQuiz"
          })
        }
      }
    },
    appBlastQuiz: {
      on: {
        DONE: {
          target: "done",
          actions: assign({ showTablet: false, tabletApp: "" })
        }
      }
    },
    done: {
      type: "last"
    }
  }
});

const objVideoCallInfo = {
  desktopSrc: dtBlastQuiz,
  name: "Blast-Quiz"
};

const InBetween = props => {
  const [current, labSend] = useMachine(labMachine);
  return (
    <Lab
      objVideoCallInfo={objVideoCallInfo}
      objState={current}
      labSend={labSend}
      nextStep={props.nextStep}
      oneStep={true}
    />
  );
};

export default InBetween;
