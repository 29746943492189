import React from "react";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Lab from "components/Lab";
import appData from "components/appCall/CallingCharlesNye";

export const labMachine = Machine({
  id: "lab",
  context: {
    scientistSubTitles: [
      '"Welcome to the MBARI Lab. Here we develop and test new ways to apply technology in exploring the Monterey Bay. In this lab you will learn about some of the basic tools you will use during your..."',
      '"assigned missions. Let’s start by clicking on the chalkboard to learn the terms you’ll need to know as you adventure on.  Have fun!"'
    ],
    tabletClickable: true,
    chalkboardGlows: false,
    chalkboardClickable: false,
    labDoorIsUnlocked: false,
    showTablet: false,
    callInProgress: false,
    callStep: 0,
    tabletApp: "",
    done: false,
    tabletRinging: true,
    calling: false,
    deskTabletOverlay: true
  },
  initial: "labIntro",
  states: {
    labIntro: {
      on: {
        "": {
          target: "labFirstRing",
          actions: assign({
            tabletClickable: true,
            tabletRinging: true,
            showTablet: false,
            tabletOverlay: true,
            calling: true
          })
        }
      } //skip welcome_explorer - might one day be needed
    },
    labFirstRing: {
      on: {
        CLICK: {
          target: "labFirstCall",
          actions: assign({
            tabletClickable: false,
            tabletRinging: false,
            showTablet: true,
            calling: true
          })
        }
      }
    },
    labFirstCall: {
      on: {
        SLIDE: {
          target: "firstCall",
          actions: assign({
            deskTabletOverlay: false,
            callInProgress: true,
            calling: true
          })
        }
      }
    },
    firstCall: {
      on: {
        FORWARD: {
          target: "read_page_one",
          actions: assign({ callStep: context => context.callStep + 1 })
        }
      }
    },
    read_page_one: {
      on: {
        FORWARD: {
          target: "read_page_two",
          actions: assign({
            callStep: context => context.callStep + 1,
            showTablet: false,
            chalkboardGlows: true,
            chalkboardClickable: true,
            tabletOverlay: false,
            callInProgress: false
          })
        }
      }
    },
    read_page_two: {
      on: {
        BACK: [
          {
            target: "read_page_one",
            cond: (context, event) => {
              return context.callStep - 1 >= 0;
            },
            actions: assign({
              callStep: context => context.callStep - 1,
              callInProgress: true
            })
          },
          {
            target: "read_page_two"
          }
        ],
        FORWARD: {
          target: "glowing_chalk_board",
          actions: assign({
            callStep: context => context.callStep + 1,
            callInProgress: false,
            done: true
          })
        }
      }
    },
    glowing_chalk_board: {
      on: {
        CLICK: {
          target: "done",
          actions: assign({ done: true })
        }
      }
    },
    done: {
      type: "final"
    }
  }
});

console.log(appData);
const objVideoCallInfo = {
  videoSrc: appData.callWithScientist,
  tabletSrc: appData.callImg,
  desktopSrc: appData.deskTopImg,

  name: appData.scientistName,
  title: appData.scientistTitle,
  text: [
    '"Welcome to the MBARI Lab. Here we develop and test new ways to apply technology in exploring the Monterey Bay. In this lab you will learn about some of the basic tools you will use during your..."',
    '"assigned missions. Let’s start by clicking on the chalkboard to learn the terms you’ll need to know as you adventure on.  Have fun!"'
  ]
};

const Intro = props => {
  const [current, labSend] = useMachine(labMachine);
  return (
    <Lab
      objVideoCallInfo={objVideoCallInfo}
      objState={current}
      labSend={labSend}
      nextStep={props.nextStep}
    />
  );
};

export default Intro;
