import React, { useRef, useContext } from "react";
// import callImg from "img/scientist_emery_nolasco.png";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Lab from "components/Lab";
import { NewLargeTablet } from "components/UIComponents";

import { AwardPageNewLevel, AwardPageRewards } from "components/TabletApps";

// import gsap from "gsap/dist/gsap";
import { Redirect } from "react-router-dom";
import appData from "components/appCall/CallingCharlesNye";
import auvBadgeImg from "img/auv_badge.png";
import mbariBadgeImg from "img/mbari_badge.png";
import UserContext from "context/User";

// import { TimelineLite, CSSPlugin } from "gsap";

//eslint-disable-next-line
// const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
// gsap.registerPlugin(CSSPlugin);

const arrBadges = [
  {
    img: auvBadgeImg,
    name: "AUV",
    type: "Action Figure",
    style: { marginTop: "-5%" }
  },
  {
    img: mbariBadgeImg,
    name: "MBARI Badge",
    type: "Enamel Pin"
  }
];

export const labMachine = Machine(
  {
    id: "outro_b",
    context: {
      chalkboardGlows: false,
      chalkboardClickable: false,
      labDoorIsUnlocked: true,
      labDoorClickable: false,
      callInProgress: false,
      callStep: 0,
      tabletApp: "",
      tabletClickable: false,
      tabletRinging: false,
      showTablet: true,
      level: 1,
      deskTabletOverlay: false,
      done: false,
      scientistName: appData.scientistName,
      scientistTitle: appData.scientistTitle,
      scientistImg: appData.callImg,
      scientistSubTitles: [
        "Hi, I am  Emery Nolasco and I am an AUV Operations Engineer. My role at MBARI involves maintaining and operating  AUVs.   There are many responsibilities associated with my position, but it is exciting..."
      ]
    },
    initial: "awardPageRewards",
    states: {
      awardPageRewards: {
        on: {
          CLAIM: {
            target: "awardPageNewLevel",
            actions: ["levelUp"]
          }
        }
      },
      awardPageNewLevel: {
        on: {
          CONTINUE: {
            target: "done",
            actions: assign({
              done: true
            })
          }
        }
      },

      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      levelUp: (context, event) => {
        context.level = context.level + 1;
      }
    }
  }
);

const LevelCleared = props => {
  const ringRef = useRef();
  const [user, setUser, levelUp] = useContext(UserContext);
  // const [ringTimeLine] = useState(new TimelineLite({ paused: true }));
  console.log("User:", user);
  user.objective = "Explore MBARI";

  const [current, mSend] = useMachine(labMachine);
  user.level = current.context.level;
  const { showTablet, done } = current.context;
  if (done) {
    return <Redirect to={props.nextStep} />;
  }
  const levelUpAction = () => {
    console.log("Level is now: ", user.level);
    levelUp();
    console.log("user is now: ", user);
    mSend("CLAIM");
  };
  const TabletContent = props => {
    console.log(current.value);
    switch (current.value) {
      case "awardPageNewLevel":
        return <AwardPageNewLevel {...props} level={user.level} />;
      case "awardPageRewards":
      default:
        return (
          <AwardPageRewards
            {...props}
            arrBadges={arrBadges}
            title="MBARI Lab"
            onClick={levelUpAction}
          />
        );
    }
  };
  // console.log(current.context);
  return (
    <Lab objState={current}>
      <NewLargeTablet showTablet={showTablet}>
        <TabletContent {...props} mSend={mSend} />
      </NewLargeTablet>
    </Lab>
  );
};

export default LevelCleared;
