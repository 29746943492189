import React from "react";
// import "./Lesson1.css";
import ExplorerBadge from "./ExplorerBadge";
import background from "img/lab_background.jpg";
import backgroundSmall from "img/lab_background_sm.jpg";
import backgroundTablet from "img/lab_background_tablet.jpg";
import backgroundTabletSmall from "img/lab_background_tablet_sm.jpg";
import lockedArea from "img/locked_area.png";
import unlockedDoor from "img/lab_door.png";
import { useBodyClass, useHover } from "./HelperFunctions";
import { NewLargeTablet } from "components/UIComponents";
import { NavLink } from "react-router-dom";
import { DeskTablet } from "./DeskTablet";
import Image from "lqip-react";
import {
  ShowVideo,
  CallWSubTitles,
  ManualInitiateCall
} from "components/TabletApps";

// // const backgroundSmall = lqip(background);
// const backgroundSmall = lqip("img/lab_background.jpg");

const Lab = props => {
  const { objVideoCallInfo, objState, labSend, nextStep, oneStep } = props;
  console.log(objState);
  const {
    tabletRinging,
    tabletClickable,
    chalkboardGlows,
    // chalkboardClickable,
    labDoorIsUnlocked,
    showTablet,
    callInProgress,
    callStep,
    deskTabletOverlay,
    // tabletApp,
    calling,
    labDoorClickable,
    watchVideo
  } = objState.context;
  console.log(objState.context);
  const BackgroundImage = () => {
    const bgImage = showTablet ? backgroundTablet : background;
    const bgImageSmall = showTablet ? backgroundTabletSmall : backgroundSmall;

    return (
      // <ProgressiveImage
      //   preview={bgImageSmall}
      //   src={bgImage}
      //   transitionTime={500}
      //   transitionFunction="ease"
      //   render={(src, style) => <img src={src} style={style} />}
      // />
      // <Image src="img/lab_background.jpg" />
      <Image
        src={bgImage}
        thumbnail={bgImageSmall}
        color="#132962"
        aspectRatio={"1920x1080"}
      />
      // <Img src={[{ bgImageSmall }, { bgImage }]} />
    );
  };

  const LabDoor = props => {
    const [hoverRef, isHovered] = useHover();
    const glow = isHovered ? "showglow" : "noglow";
    console.log("nextStep:", props.nextStep);
    const WithNav = props => {
      if (labDoorClickable) {
        return <NavLink to={props.nextStep}>{props.children}</NavLink>;
      } else {
        return <>{props.children}</>;
      }
    };
    return (
      <WithNav>
        <div className="lab_door" ref={hoverRef}>
          <img alt="Lab Door" src={unlockedDoor} className={glow} />
        </div>
      </WithNav>
    );
  };
  const Door = props => {
    console.log(labDoorIsUnlocked);
    if (labDoorIsUnlocked) {
      return <LabDoor {...props} />;
    }
    return (
      <div className="locked_area">
        <img alt="Locked Door" src={lockedArea} />
      </div>
    );
  };
  const DeskScreen = props => {
    console.log("objVideoInfo", objVideoCallInfo);
    console.log("calling", calling);

    const smallTabletScreen = calling ? objVideoCallInfo["desktopSrc"] : "";
    let dtClass = "desk_tablet_screen" + (tabletRinging ? " blinking" : "");
    console.log("calling", calling);
    return deskTabletOverlay ? (
      <div className={dtClass}>
        <img alt="Tablet screen" src={smallTabletScreen} />
      </div>
    ) : null;
  };
  const ChalkBoard = props => {
    return chalkboardGlows ? (
      <NavLink to={props.nextStep}>
        <div className="glowing_board blinking" />
        {/* <img alt="Tablet screen highlight" src={glowingChalkBoard} />
        </div> */}
      </NavLink>
    ) : null;
  };

  const TabletContent = props => {
    props.current.context.scientistName = objVideoCallInfo.name;
    props.current.context.scientistTitle = objVideoCallInfo.title;
    if (watchVideo) {
      return <ShowVideo {...props} />;
    }
    // return <CallScientist />;
    if (callInProgress) {
      return (
        <CallWSubTitles
          {...props}
          // tabletBG={objVideoCallInfo.tabletSrc}
          videoImg={objVideoCallInfo.videoSrc}
          current={objState}
          callStep={callStep}
        />
      );
    }

    return (
      <ManualInitiateCall
        {...props}
        mSend={props.mSend}
        callImg={objVideoCallInfo.tabletSrc}
      />
    );
  };
  useBodyClass(showTablet ? "tablet_mode" : "lab_mode");

  console.log("props.children:", props.children);
  return (
    <div className={"lab_container" + (props.review ? " blur_bg" : "")}>
      <BackgroundImage />
      {!props.review && <ExplorerBadge objective="Explore MBARI" />}

      {!props.review && <DeskScreen />}
      {!props.review && (
        <DeskTablet
          mode={showTablet ? "tablet_mode" : "lab_mode"}
          send={labSend}
          visible={tabletClickable}
          nextStep={oneStep ? nextStep : null}
        />
      )}
      {!props.review && <ChalkBoard />}
      {props.children ? (
        props.children
      ) : (
        // <LargeTablet showTablet={showTablet}>
        //   <TabletContent />
        // </LargeTablet>
        <NewLargeTablet showTablet={showTablet}>
          <TabletContent {...props} mSend={labSend} current={objState} />
        </NewLargeTablet>
      )}

      {!props.review && <Door {...props} />}
    </div>
  );
  // }
};

export default Lab;
