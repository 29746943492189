import React from "react";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import Lab from "components/Lab";

export const labMachine = Machine({
  id: "lab_opens",
  context: {
    tabletClickable: false,
    tabletRinging: false,
    chalkboardGlows: false,
    chalkboardClickable: false,
    labDoorIsUnlocked: true,
    labDoorClickable: true,
    showTablet: false,
    callInProgress: false,
    callStep: 0,
    tabletApp: ""
  },
  initial: "labOpen",
  states: {
    labOpen: {
      type: "final"
    }
  }
});

const objVideoCallInfo = null;

const LabOpens = props => {
  const [current, labSend] = useMachine(labMachine);
  return (
    <Lab
      objVideoCallInfo={objVideoCallInfo}
      objState={current}
      labSend={labSend}
      nextStep={props.nextStep}
    />
  );
};

export default LabOpens;
