import callImg from "img/appCall/kelly_benoit-bird_calling.png";
import callWithScientist from "img/appCall/call_with_kelly_benoit_bird.png";
import imgOnTableTablet from "img/appCall/table_tablet_kelly.png";
import dtKellyBenoitBird from "img/appCall/dt_kelly_benoit_bird.png";

const appData = {
  callImg: callImg,
  callWithScientist: callWithScientist,
  scientistName: "Kelly Benoit-Bird",
  scientistTitleShort: "Senior Scientist",
  scientistTitle: "MBARI Senior Scientist",
  imgOnTableTablet: imgOnTableTablet,
  deskTopImg: dtKellyBenoitBird
};
export default appData;
