import React, { useState, useCallback, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Environment } from "components/Environment";
import { NewLargeTablet, DisplayObtainedItem } from "components/UIComponents";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import imgKayakStation from "img/ks_background_blur.jpg";
// import imgKayakStationSmall from "img/ks_background_sm.jpg";
import imgKayakStationTablet from "img/ks_background_tablet.jpg";
import imgKayakStationTabletSmall from "img/ks_background_tablet_sm.jpg";
import largeRings from "img/ks_large_rings.svg";
import largePCR from "img/ks_large_qpcr.svg";
import imgTesttube1 from "img/ks_testtube1.svg";
import imgTesttube2 from "img/ks_testtube2.svg";
import imgTesttube3 from "img/ks_testtube3.svg";
import imgTesttube4 from "img/ks_testtube4.svg";
import imgTesttube5 from "img/ks_testtube5.svg";
import imgVideoStill from "img/ks_testing_video_still.svg";

import imgTableWTablet from "img/table_w_tablet.png";
// import imgOnTableTablet from "img/table_tablet_kelly.png";
import imgTableTabletHighlight from "img/table_tablet_highlight.svg";
import imgKayakShackDoorHighlight from "img/ks_door_highlight.svg";
import imgCrate from "img/ks_crate.png";
// import imgSensorHighlight from "img/ks_sensor_highlight.svg";
// import imgSensorSmall from "img/ks_sensor_small.png";
// import FaArrowCircleRight from "react-icons/lib/fa/arrow-circle-right";
// import FaArrowCircleLeft from "react-icons/lib/fa/arrow-circle-left";
import tabletBG from "img/tablet_background.jpg";
// import callImg from "img/kelly_benoit-bird_calling.png";
// import cropRings from "img/crop_rings.svg";
import imgQPCR from "img/ks_qPCR_obtained.png";
// import callWithKellyBenoitBird from "img/call_with_kelly_benoit_bird.png";
import gsap from "gsap/dist/gsap";
import { TimelineLite, CSSPlugin } from "gsap";
import UserContext from "context/User";
import appData from "components/appCall/CallingCharlesNye";

import { LessonPage, TestingPage } from "components/TabletApps";
// import Lesson from "components/Lesson1/lesson";
//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code
gsap.registerPlugin(CSSPlugin);

function subTwoPercentages(one, two) {
  return (parseFloat(one) - parseFloat(two)).toString(10) + "%";
}
export const envMachine = Machine(
  {
    id: "waterTesting",
    context: {
      environment: {
        className: "env_elkhorn",
        shiftBackground: false,
        // shiftLeft: "8.6%",
        background: {
          optimize: false,
          src: imgKayakStation,
          // src: imgKayakStationTablet,
          // thumbnail: imgKayakStationTabletSmall,
          alt: "Elkorn Slough Kayak Station",
          color: "#132962",
          aspectRatio: "1920x1080"
        },
        clickables: {
          largeRings: {
            visible: true,
            className: "large_rings",
            layers: [
              {
                name: "background",
                optimize: false,
                src: largeRings,
                style: {
                  position: "absolute",
                  top: "18%",
                  left: "6%",
                  width: "40%",
                  opacity: "0.5"
                }
              }
            ]
          },
          largePCR: {
            visible: true,
            className: "large_pcr",
            layers: [
              {
                name: "background",
                optimize: false,
                src: largePCR,
                style: {
                  position: "absolute",
                  top: "38%",
                  left: "20%",
                  width: "12%"
                }
              }
            ]
          },
          testtube1: {
            visible: true,
            className: "testtube_1",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube1,
                style: {
                  position: "absolute",
                  top: "50%",
                  left: "1%",
                  width: "18%"
                }
              }
            ]
          },
          testtube2: {
            visible: true,
            className: "testtube_2",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube2,
                style: {
                  position: "absolute",
                  top: "15%",
                  left: "4%",
                  width: "18%"
                }
              }
            ]
          },
          testtube3: {
            visible: true,
            className: "testtube_3",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube3,
                style: {
                  position: "absolute",
                  top: "8%",
                  left: "25%",
                  width: "18%"
                }
              }
            ]
          },
          testtube4: {
            visible: true,
            className: "testtube_4",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube4,
                style: {
                  position: "absolute",
                  top: "45%",
                  left: "35%",
                  width: "18%"
                }
              }
            ]
          },
          testtube5: {
            visible: true,
            className: "testtube_5",
            layers: [
              {
                name: "background",
                optimize: false,
                src: imgTesttube5,
                style: {
                  position: "absolute",
                  top: "68%",
                  left: "18%",
                  width: "18%"
                }
              }
            ]
          }
        }
      },
      callStep: 0,
      callImg: appData.callImg,
      tabletBG: tabletBG,
      scientistName: appData.scientistName,
      scientistTitle: appData.scientistTitle,
      videoStill: appData.callWithScientist,
      scientistSubTitles: [
        "“Glad to see you once again. Remember that blue box that you found earlier? That is qPCR device. I've sent you video instructions on how to use it. Follow the instructions and help us test the samples.”"
      ],
      showTablet: true,
      itemObtained: null
    },
    initial: "howToSample",
    states: {
      howToSample: {
        on: {
          PLAY: {
            target: "playVideo",
            actions: assign({})
          },
          READY: {
            target: "testingSamples",
            actions: assign({
              callConnected: true
            })
          }
        }
      },
      playVideo: {
        on: {
          "": [
            {
              target: "howToSample",
              actions: [assign({})]
            }
          ]
        }
      },
      testingSamples: {
        on: {
          DROP: [
            {
              target: "testingSamples",
              actions: [assign({})]
            }
          ],
          TEST: [
            {
              target: "testResults",
              actions: [assign({})]
            }
          ]
        }
      },
      testResults: {
        on: {
          TRANSFER: [
            {
              target: "done",
              actions: [assign({ done: true })]
            }
          ]
        }
      },
      displayOnMap: {
        on: {
          TRANSFER: [
            {
              target: "displayOnMap",
              actions: [assign({})]
            }
          ]
        }
      },

      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      createShiftedStyle: (context, event) => {
        // console.log("HERE!");
        Object.keys(context.environment.clickables).forEach(function(key) {
          const clickable = context.environment.clickables[key];
          console.log(clickable);
          clickable.shiftedStyle = {};
          Object.assign(clickable.shiftedStyle, { ...clickable.style });
          clickable.shiftedStyle.left = subTwoPercentages(
            clickable.style.left,
            context.environment.shiftLeft
          );
          // console.log("HERE! shiftedStyle = ", clickable.shiftedStyle);
          // console.log("HERE! style = ", clickable.style);
        });
      },
      storeItem: (context, event) => {
        context.itemStored = context.itemObtained;
        context.itemObtained = null;
        context.environment.clickables.shackDoor.showHighlight = true;
        context.environment.clickables.shackDoor.visible = true;
      },
      pickUpTablet: (context, event) => {
        context.showTablet = true;
        context.environment.background.src = imgKayakStationTablet;
        context.environment.background.thumbnail = imgKayakStationTabletSmall;
        context.environment.shiftBackground = true;
        // console.log("HERE:", context.environment.background);
        context.callInProgress = true;
        context.callConnected = false;
        context.environment.clickables.tableTablet.showHighlight = false;
        context.environment.clickables.tableTablet.ringing = false;
      },
      obtainItem: (context, event) => {
        context.itemObtained = context.environment.obtainable;
        context.environment.clickables.crateWSensor.visible = false;
      },
      finishCall: (context, event) => {
        context.callStep = 0;
        context.callInProgress = false;
        context.showTablet = false;
        context.environment.clickables.crateWSensor.showHighlight = true;
        context.environment.background.src = imgKayakStation;
        context.environment.shiftBackground = false;
        context.done = true;
        // console.log("CONTEXT IS NOW:", context);
      }
    }
  }
);

const Review = props => {
  const [user] = useContext(UserContext);
  // const ringRef = useRef();
  const [ringTimeLine] = useState(new TimelineLite({ paused: true }));

  user.level = props.lesson;
  user.objective = "Elkhorn Slough & Kayak Station";
  const animateRing = useCallback(
    (context, event) => {
      console.log("start this!");
      return new Promise(resolve => {
        ringTimeLine.eventCallback("onComplete", function() {
          console.log("on complete resolving");
          resolve();
        });
        ringTimeLine.restart();
      });
    },
    [ringTimeLine]
  );
  const [current, mSend] = useMachine(envMachine, {
    services: { animateRing }
  });
  console.log(current.value);
  if (current.value === "done") {
    return <Redirect to={props.nextStep} />;
  }

  const TabletContent = props => {
    const { current } = props;
    // console.log(current.value);
    switch (current.value) {
      case "testingSamples":
        const arrSamples = [
          { color: "#FFB802", marker: "#1", name: "First sample loaded" },
          { color: "#029BFA", marker: "#2", name: "Second sample loaded" },
          { color: "#8618C8", marker: "#3", name: "Third sample loaded" },
          { color: "#DB493D", marker: "#4", name: "Fourth sample loaded" },
          { color: "#1A9B5D", marker: "#5", name: "Fifth sample loaded" }
        ];
        return (
          <TestingPage
            title="Water Testing"
            instructions="Drag'n'Drop sampling tubes onto the qPCR device to start testing."
            buttonText="Test Samples"
            buttonAction="TEST"
            samples={arrSamples}
            mSend={mSend}
          />
        );
      case "testResults":
        const arrSampleResults = [
          { color: "#FFB802", marker: "#1", name: "143 cfu / 100mL" },
          { color: "#029BFA", marker: "#2", name: "150 cfu / 100 mL" },
          { color: "#8618C8", marker: "#3", name: "498 cfu / 100mL" },
          { color: "#DB493D", marker: "#4", name: "425 cfu / 100mL" },
          { color: "#1A9B5D", marker: "#5", name: "302 cfu / 100mL" }
        ];
        return (
          <TestingPage
            title="Water Testing"
            instructions="Here are the results.  CFU stands for Colony Forming Units."
            buttonText="Transfer to the map"
            buttonAction="TRANSFER"
            samples={arrSampleResults}
            mSend={mSend}
          />
        );
      case "howToSample":
      default:
        return (
          <LessonPage
            title="Water Testing"
            introduction="Here's how we prepare the water samples in the lab for testing"
            videoStillAlt="Lab technician, testing samples"
            videoStill={imgVideoStill}
            videoCaption='Since you are in the field, drag each water sample to the qPCR device.
        Once you are done, hit "TEST SAMPLES" and the device will read the samples and deliver the data.'
            buttonText="I'm ready"
            buttonAction="READY"
            mSend={mSend}
          />
        );
    }
  };
  // console.log("ENVIRONMENT:", current.context.environment);
  return (
    <Environment
      {...props}
      environment={current.context.environment}
      itemStored={current.context.itemStored}
      mSend={mSend}
    >
      {current.context.itemObtained && (
        <DisplayObtainedItem
          obtainable={current.context.itemObtained}
          mSend={mSend}
        />
      )}
      <NewLargeTablet showTablet={current.context.showTablet}>
        <TabletContent {...props} mSend={mSend} current={current} />
      </NewLargeTablet>
    </Environment>
  );
};

export default Review;
