import "./App.scss";
import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import MBARIMap from "components/MBARIMap";
import ErrorPage from "components/ErrorPage";
// import L1 from "components/Lesson1";
// import Lab from "components/LabTest";

import UserContext from "context/User.js";
import explorerThumbnail from "img/explorer_thumbnail.png";
import SiteStates from "components/SiteStates";
import L1Intro from "components/Lesson1/intro";

import L1Lesson from "components/Lesson1/lesson";
import L1BlastQuiz from "components/Lesson1/blastQuiz";
import L1InBetween from "components/Lesson1/inBetween";
// import ChalkboardTest from "components/ChalkboardTest";
import L1ChalkboardText from "components/Lesson1/chalkboardDnD";
import AUVGame from "components/AUVGame";
import L1LabOpens from "components/Lesson1/labOpens";
import L1GameFinish from "components/Lesson1/gameFinish";
import L1AskScientist from "components/Lesson1/askScientist";
import L1Notes from "components/Lesson1/notes";
import L1OutroA from "components/Lesson1/outroA";
import L1Congratulations from "components/Lesson1/outroB";
import L1Final from "components/Lesson1/final";
import EnvTest from "components/Lesson2/intro";
import L2Intro from "components/Lesson2/intro";
import L2KayakShack from "components/Lesson2/kayakShack";
import L2Clipboard from "components/Lesson2/clipboardDnD";
import L2Quiz from "components/Lesson2/quiz";
import L2KayakUnlocked from "components/Lesson2/kayakUnlocked";
import L2KayakCollect from "components/Lesson2/kayakCollect";
import L2Review from "components/Lesson2/kayakReview";
import L2WaterTesting from "components/Lesson2/waterTesting";
import L2AskScientist from "components/Lesson2/askScientist";
import L2FinalTest from "components/Lesson2/finalTest";
import L2OutroA from "components/Lesson2/outroA";
import L2Notes from "components/Lesson2/notes";
import L2OutroB from "components/Lesson2/outroB";
import L2Congratulations from "components/Lesson2/congratulations";
import L2Final from "components/Lesson2/final";
// import L2NewOutroA from "components/Lesson2/newOutroA";

// import GSAPDemo from "components/GSAPDemo";

const App = props => {
  const [user, setUser] = useState({
    firstName: "Lily",
    lastName: "Wilson",
    name: function() {
      return this.firstName + " " + this.lastName;
    },
    title: "Junior Explorer",
    level: 1,
    objective: "Welcome!",
    badge_thumbnail: explorerThumbnail,
    badge_image: "",
    state: SiteStates.WELCOME,
    nextStep: "" //"TEST_BLAST_QUIZ"
  });
  const levelUp = () => {
    console.log("Leveling up!");
    user.level = user.level + 1;
    setUser({ ...user, level: user.level });
  };
  // console.log("starting");

  return (
    <UserContext.Provider value={[user, setUser, levelUp]}>
      <BrowserRouter>
        {/* <Navigation /> */}
        <Switch>
          <Route exact path="/" render={props => <MBARIMap {...props} />} />
          <Route path="/map" component={MBARIMap} />
          <Route
            path="/lab/intro"
            render={props => <L1Intro {...props} nextStep={"/lab/test"} />}
          />
          <Route
            path="/lab/test"
            render={props => (
              <L1ChalkboardText {...props} nextStep={"/lab/lesson"} />
            )}
          />
          <Route
            path="/lab/lesson"
            render={props => (
              <L1Lesson {...props} nextStep={"/lab/inbetween"} />
            )}
          />
          <Route
            path="/lab/inbetween"
            render={props => (
              <L1InBetween {...props} nextStep={"/lab/blastquiz"} />
            )}
          />
          <Route
            path="/lab/blastquiz"
            render={props => (
              <L1BlastQuiz {...props} nextStep={"/lab/labopens"} />
            )}
          />
          <Route
            path="/lab/labopens"
            render={props => (
              <L1LabOpens {...props} nextStep={"/lab/auvgame"} />
            )}
          />
          <Route
            path="/lab/auvgame"
            render={props => (
              <AUVGame {...props} nextStep={"/lab/gamefinish"} />
            )}
          />
          <Route
            path="/lab/gamefinish"
            render={props => (
              <L1GameFinish {...props} nextStep={"/lab/askscientist"} />
            )}
          />
          <Route
            path="/lab/askscientist"
            render={props => (
              <L1AskScientist {...props} nextStep={"/lab/notes"} />
            )}
          />
          <Route
            path="/lab/notes"
            render={props => <L1Notes {...props} nextStep={"/lab/outro"} />}
          />
          <Route
            path="/lab/outro"
            render={props => (
              <L1OutroA {...props} nextStep={"/lab/congratulations"} />
            )}
          />
          <Route
            path="/lab/congratulations"
            render={props => (
              <L1Congratulations {...props} nextStep={"/lab/final"} />
            )}
          />
          <Route
            path="/lab/final"
            render={props => <L1Final {...props} nextStep={"/elkhorn/map"} />}
          />
          <Route
            path="/lab/mainscreen"
            render={props => <MBARIMap {...props} mainScreen={true} />}
          />
          <Route
            path="/elkhorn/map"
            render={props => <MBARIMap {...props} lesson={2} />}
          />
          <Route
            path="/elkhorn/intro"
            render={props => (
              <L2Intro {...props} lesson={2} nextStep={"/elkhorn/kayakshack"} />
            )}
          />
          <Route
            path="/elkhorn/kayakshack"
            render={props => (
              <L2KayakShack {...props} lesson={2} nextStep={"/elkhorn/test"} />
            )}
          />
          <Route
            path="/elkhorn/test"
            render={props => (
              <L2Clipboard {...props} lesson={2} nextStep={"/elkhorn/quiz"} />
            )} //nextStep={"/elkhorn/clipboard"}
          />
          <Route
            path="/elkhorn/quiz"
            render={props => (
              <L2Quiz
                {...props}
                lesson={2}
                nextStep={"/elkhorn/kayakunlocked"}
              />
            )}
          />
          <Route
            path="/elkhorn/kayakunlocked"
            render={props => (
              <L2KayakUnlocked
                {...props}
                lesson={2}
                nextStep={"/elkhorn/kayakcollect"}
              />
            )}
          />
          <Route
            path="/elkhorn/kayakcollect"
            render={props => (
              <L2KayakCollect
                {...props}
                lesson={2}
                nextStep={"/elkhorn/kayakreview"}
              />
            )}
          />
          <Route
            path="/elkhorn/kayakreview"
            render={props => (
              <L2Review
                {...props}
                lesson={2}
                nextStep={"/elkhorn/watertesting"}
              />
            )}
          />
          <Route
            path="/elkhorn/watertesting"
            render={props => (
              <L2WaterTesting
                {...props}
                lesson={2}
                nextStep={"/elkhorn/askscientist"}
              />
            )}
          />
          <Route
            path="/elkhorn/askscientist"
            render={props => (
              <L2AskScientist
                {...props}
                lesson={2}
                nextStep={"/elkhorn/finaltest"}
              />
            )}
          />
          <Route
            path="/elkhorn/finaltest"
            render={props => (
              <L2FinalTest {...props} lesson={2} nextStep={"/elkhorn/outro"} />
            )}
          />
          <Route
            path="/elkhorn/outro"
            render={props => (
              <L2OutroA {...props} lesson={2} nextStep={"/elkhorn/notes"} />
            )}
          />
          <Route
            path="/elkhorn/notes"
            render={props => (
              <L2Notes {...props} lesson={2} nextStep={"/elkhorn/outro_b"} />
            )}
          />
          <Route
            path="/elkhorn/outro_b"
            render={props => (
              <L2OutroB
                {...props}
                lesson={2}
                nextStep={"/elkhorn/congratulations"}
              />
            )}
          />
          <Route
            path="/elkhorn/congratulations"
            render={props => (
              <L2Congratulations
                {...props}
                lesson={2}
                nextStep={"/elkhorn/final"}
              />
            )}
          />
          <Route
            path="/elkhorn/final"
            render={props => (
              <L2Final {...props} lesson={3} nextStep={"/tidepools/map"} />
            )}
          />
          <Route
            path="/elkhorn/map"
            render={props => <MBARIMap {...props} lesson={2} />}
          />
          {/* <Route
            path="/elkhorn/newoutro_a"
            render={props => (
              <L2NewOutroA {...props} lesson={2} nextStep={"/elkhorn/map"} />
            )}
          /> */}
          <Route
            path="/test"
            render={props => <EnvTest {...props} lesson={2} />}
          />
          <Route
            path="/tidepools/map"
            render={props => <MBARIMap {...props} lesson={3} />}
          />
          <Route path="/map" component={MBARIMap} />

          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default App;
