import React from "react";
// import dtCharlesNye from "img/dt_charles_nye.png";
// import callingCharlesNye from "img/calling_charles_nye.png";
// import callWithCharlesNye from "img/call_with_charles_nye.png";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Lab from "components/Lab";
import { Redirect } from "react-router-dom";
import appData from "components/appCall/CallingCharlesNye";

export const labMachine = Machine({
  id: "gamefinish",
  context: {
    scientistSubTitles: [
      "Great work! Now you know how to use basic block code to control the AUV. Now, let’s take a look at how our tools, lab and team of scientists and engineers come together to conduct ocean research."
    ],
    chalkboardGlows: false,
    chalkboardClickable: false,
    labDoorIsUnlocked: true,
    callInProgress: false,
    callStep: 0,
    tabletApp: "",
    tabletClickable: false,
    tabletRinging: false,
    showTablet: true,
    calling: "charles_nye",
    deskTabletOverlay: true,
    done: false
  },
  initial: "gameFinishCall",
  states: {
    gameFinishCall: {
      on: {
        SLIDE: {
          target: "takeCall",
          actions: assign({
            deskTabletOverlay: false,
            callInProgress: true,
            calling: "charles_nye"
          })
        }
      }
    },
    takeCall: {
      on: {
        FORWARD: {
          target: "showVideo",
          actions: assign({
            callStep: context => context.callStep + 1,
            callInProgress: false,
            watchVideo: true
          })
        }
      }
    },
    showVideo: {
      on: {
        VIDEODONE: {
          target: "videoDone",
          actions: assign({})
        }
      }
    },
    videoDone: {
      on: {
        "": {
          target: "done",
          actions: assign({
            done: true
          })
        }
      }
    },

    done: {
      type: "final"
    }
  }
});

const objVideoCallInfo = {
  videoSrc: appData.callWithScientist,
  tabletSrc: appData.callImg,
  desktopSrc: appData.deskTopImg,

  name: appData.scientistName,
  title: appData.scientistTitle,
  text: [
    "Great work! You now know how to use basic block code to control the AUV.  This will come in handy later."
  ]
};

const GameFinish = props => {
  const [current, labSend] = useMachine(labMachine);
  console.log("Done:", current.context.done);
  console.log("NextStep:", props.nextStep);
  if (current.context.done) {
    return <Redirect to={props.nextStep} />;
  }
  console.log(current);
  return (
    <Lab
      objVideoCallInfo={objVideoCallInfo}
      objState={current}
      labSend={labSend}
      nextStep={props.nextStep}
      videoTitle="Automating deep-sea exploration"
      videoIntroduction="A new robot that surveys the deep sea, collecting data autonomously, is providing a boost to one of MBARI’s longest running research projects."
      videoCaption="AUVs and ROVs are helping MBARI explore."
      onEnd={() => labSend("VIDEODONE")}
      mSend={labSend}
      noButton={true}
    />
  );
};

export default GameFinish;
