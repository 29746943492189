import React from "react";
// import dtCharlesNye from "img/dt_charles_nye.png";
// import callingCharlesNye from "img/calling_charles_nye.png";
// import callWithCharlesNye from "img/call_with_charles_nye.png";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import Lab from "components/Lab";
import { Redirect } from "react-router-dom";
import appData from "components/appCall/CallingCharlesNye";
// import awardBG from "img/outro_award_bg.jpg";

export const labMachine = Machine({
  id: "gamefinish",
  context: {
    scientistSubTitles: [
      "It seems like there is nothing more to explore here. You’ve been to every corner of the MBARI lab... Almost."
    ],
    chalkboardGlows: false,
    chalkboardClickable: false,
    labDoorIsUnlocked: true,
    callInProgress: false,
    callStep: 0,
    tabletApp: "",
    tabletClickable: false,
    tabletRinging: false,
    showTablet: true,
    calling: "charles_nye",
    tabletOverlay: true,
    done: false
  },
  initial: "gameFinishCall",
  states: {
    gameFinishCall: {
      on: {
        SLIDE: {
          target: "takeCall",
          actions: assign({ callInProgress: true, calling: "charles_nye" })
        }
      }
    },
    takeCall: {
      on: {
        FORWARD: {
          target: "done",
          actions: assign({
            callStep: context => context.callStep + 1,
            callInProgress: false,
            done: true
          })
        }
      }
    },

    done: {
      type: "final",
      actions: assign({
        callStep: 1,
        callInProgress: false,
        done: false
      })
    }
  }
});

const objVideoCallInfo = {
  videoSrc: appData.callWithScientist,
  tabletSrc: appData.callImg,
  desktopSrc: appData.deskTopImg,

  name: appData.scientistName,
  title: appData.scientistTitle,
  text: [
    "It seems like there is nothing more to explore here. You’ve been to every corner of the MBARI lab... Almost."
  ]
};

const Outro = props => {
  const [current, labSend] = useMachine(labMachine);
  if (current.context.done) {
    return <Redirect to={props.nextStep} />;
  }
  console.log(current);
  return (
    <Lab
      objVideoCallInfo={objVideoCallInfo}
      objState={current}
      labSend={labSend}
      nextStep={props.nextStep}
    />
  );
};

export default Outro;
