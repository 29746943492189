/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useRef, useState, useCallback } from "react";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import ExplorerBadge from "./ExplorerBadge";
import background from "img/auv_bg.jpg";
import backgroundSmall from "img/auv_bg_sm.jpg";
import subIcon from "img/sub_icon.png";
import { LargeTablet } from "./UIComponents";
import auv from "img/auvgame/auv.png";
import bluefish1 from "img/auvgame/bluefish1.png";
import bluefish2 from "img/auvgame/bluefish2.png";
import redfish1 from "img/auvgame/redfish1.png";
import redfish2 from "img/auvgame/redfish2.png";
import yellowfish1 from "img/auvgame/yellowfish1.png";
import yellowfish2 from "img/auvgame/yellowfish2.png";
import auvVideoStill from "img/auvgame/auv_video_still.png";
import targetOpen from "img/auvgame/target_open.png";
import finalTarget from "img/auvgame/final_target.png";
import runIcon from "img/auvgame/play_icon.png";
import stopIcon from "img/auvgame/stop_icon.png";
import arrowIcon from "img/auvgame/arrow_icon.png";
import { TimelineLite, CSSPlugin } from "gsap";
import styled from "@emotion/styled";
import { ButtonCenter, AppButton } from "./Tablet";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { v4 as uuid } from "uuid";
import { DragDropContext } from "react-beautiful-dnd";
import { AUVControlPad, AUVProgramPad } from "components/UIComponents";
// import { ProgressiveImage } from "react-progressive-image-loading";
import Image from "lqip-react";
import { Redirect } from "react-router-dom";

//eslint-disable-next-line
const plugins = [CSSPlugin]; // THIS MUST STAY.  Needed to prevent tree-shaking bug when building code

/**
 * Final demo of a State Machine Component.
 * see https://gedd.ski/post/state-machines-in-react for more info
 */

/**
 * State Machine - the "brain"
 */
const menuMachine = Machine(
  {
    context: {
      showIntro: false,
      gameStarted: true,
      // animateAUV: false,
      runningAUV: false,
      pathAUV: [
        { Forward: 2 },
        { Dive: 6 },
        { Back: 1 },
        { Dive: 1 },
        { Forward: 4 },
        { Rise: 2 },
        { Forward: 2 },
        { Rise: 2 },
        { Forward: 2 }
      ],
      positionAUV: [0, 7],
      runNeeded: true,
      done: false
    },
    initial: "startProgramming",
    states: {
      startProgramming: {
        on: {
          // DRAGCOMMAND: {
          //   target: "programming",
          //   actions: ["initializePath"]
          // },
          DRAGRUN: {
            target: "programming",
            actions: ["initializePath"]
          },
          DONE: {
            target: "done",
            actions: ["finishUp"]
          }
        }
      },
      programming: {
        on: {
          DRAGCOMMAND: {
            target: "programming",
            actions: ["addInstructions"]
          },
          CLEARINSTRUCTIONS: {
            target: "programming",
            actions: ["clearInstructions"]
          },
          RUN: "running",
          DONE: {
            target: "done",
            actions: ["finishUp"]
          }
        }
      },
      running: {
        invoke: {
          src: "animateAUV",
          onDone: { target: "checkResults" }
        },
        on: {
          STOP: "stopping"
        }
      },
      checkResults: {
        on: {
          STOP: "stopping",
          RUN: "running"
        }
      },
      stopping: {
        invoke: {
          src: "stopAUV",
          onDone: { target: "programming" }
        },
        on: {
          RUN: "running"
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      finishUp: (context, event) => {
        context.done = true;
      },
      initializePath: (context, event) => {
        console.log("Resetting Path");
        context.pathAUV = [];
        context.runNeeded = false;
      },
      clearInstructions: (context, event) => {
        console.log("Clearing Instruction:", event);
        context.pathAUV = [];
      },
      addInstructions: (context, event) => {
        console.log("Adding Instruction:", event);
        context.pathAUV = [];
        event.instructions.forEach(instruction => {
          console.log(instruction.label);
          instruction.label !== "Run" &&
            context.pathAUV.push({
              [instruction.label]: parseInt(instruction.distance)
            });
        });
      }
    }
  }
);

const arrFishLocations = [
  { location: [0, 0], fish: { bluefish2 }, size: 0.4 },
  { location: [0, 4], fish: { bluefish1 }, size: 1 },
  { location: [1, 2], fish: { yellowfish1 }, size: 0.47 },
  { location: [3, 6], fish: { redfish1 }, size: 0.36 },
  { location: [4, 1], fish: { redfish1 }, size: 0.61 },
  { location: [4, 2], fish: { bluefish2 }, size: 0.76 },
  { location: [4, 6], fish: { yellowfish1 }, size: 0.76 },
  { location: [5, 4], fish: { yellowfish2 }, size: 0.65 },
  { location: [5, 7], fish: { bluefish1 }, size: 0.87 },
  { location: [6, 3], fish: { bluefish2 }, size: 0.91 },
  { location: [7, 5], fish: { redfish1 }, size: 0.91 },
  { location: [7, 7], fish: { redfish2 }, size: 0.36 },
  { location: [8, 9], fish: { yellowfish2 }, size: 0.77 },
  { location: [9, 0], fish: { bluefish1 }, size: 0.65 },
  { location: [9, 3], fish: { bluefish2 }, size: 1 },
  { location: [9, 6], fish: { bluefish2 }, size: 0.5 }
];
const targetLocations = [
  [1, 0],
  [2, 5],
  [5, 2],
  [7, 3],
  [9, 4]
];

/**
 * React component - the "body"
 */
const AUVGame = props => {
  const [programPadItems, setProgramPadItems] = useState([]);
  const auvElement = useRef();
  const [tl] = useState(new TimelineLite({ paused: true }));

  const runTimeline = () => {
    mSend("RUN");
  };

  const eraseProgramPad = () => {
    mSend("CLEARINSTRUCTIONS");
    setProgramPadItems([]);
    generateTimeline();
    // console.log("Pausing");
  };

  const animateAUV = useCallback(
    (context, event) => {
      return new Promise(resolve => {
        tl.eventCallback("onComplete", function() {
          resolve();
        });
        tl.restart();
      });
    },
    [tl]
  );

  const stopAUV = useCallback(
    (context, event) => {
      return new Promise(resolve => {
        tl.pause();
      });
    },
    [tl]
  );

  const generateTimeline = () => {
    console.log("initializing timeLine");
    const convertToLeftTop = instruction => {
      // console.log(instruction);
      const move = Object.keys(instruction)[0];
      const count = Object.values(instruction)[0] * 10;

      console.log(count);
      switch (move) {
        case "Forward":
          return { left: "+=" + count + "%" };
        case "Dive":
          return { top: "+=" + count + "%" };
        case "Rise":
          return { top: "-=" + count + "%" };
        case "Back":
        default:
          return { left: "-=" + count + "%" };
      }
    };

    tl.killTweensOf(auvElement.current);
    console.log(tl);
    let tlTemp = tl;
    tlTemp.to(auvElement.current, 1, {
      left: `${(((positionAUV[0] / 10) * 1920) / 1920) * 100 + offsetX}%`,
      top: `${((((9 - positionAUV[1]) / 10) * 1080) / 1080) * 100 + offsetY}%`
    });
    console.log("RECALCULATING timeline animation", pathAUV);
    pathAUV.forEach(function(instruction, stepCount) {
      const speed = Math.abs(Object.values(instruction)[0]);
      tlTemp = tlTemp.to(auvElement.current, speed / 2, {
        ...convertToLeftTop(instruction),
        ...{ delay: 0.5 }
      });
    });
  };

  const [current, mSend] = useMachine(menuMachine, {
    services: {
      animateAUV,
      stopAUV
    }
  });

  // console.log("Current=", current);

  const {
    pathAUV,
    positionAUV,
    showIntro,
    gameStarted,
    runNeeded,
    done
  } = current.context;

  console.log("pathAUV=", pathAUV);
  console.log("done=", done);

  if (done) {
    return <Redirect to={props.nextStep} />;
  }
  // useEffect(() => {
  //   generateTimeline();
  // }, [tl, auvElement, pathAUV]);
  // keyboard shortcut for stopping the menu
  // useEffect(() => {
  //   mSend("DRAGCOMMAND");
  // }, [mSend]);

  const BackgroundImage = () => {
    return (
      <div className="auv_bg">
        <Image
          src={background}
          thumbnail={backgroundSmall}
          color="#132962"
          aspectRatio={"1920x1080"}
        />
        {/* <ProgressiveImage
          preview={backgroundSmall}
          src={background}
          transitionTime={500}
          transitionFunction="ease"
          render={(src, style) => (
            <img src={src} style={style} alt="Grid Pattern over Water" />
          )}
        /> */}
      </div>
    );
  };

  const Fish = props => {
    const preScaleX = 15;
    const preScaleY = 15;

    const schoolOffsetX = 5;
    const schoolOffsetY = 10;
    const schoolOfFish = arrFishLocations.map((objFish, fishCount) => (
      <div
        key={fishCount}
        style={{
          width: `${objFish.size * preScaleX}%`,
          height: `${objFish.size * preScaleY}%`,
          position: "absolute",
          left: `${(((objFish.location[0] / 10) * 1920) / 1920) * 100 +
            schoolOffsetX}%`,
          top: `${((((9 - objFish.location[1]) / 10) * 1080) / 1080) * 100 +
            schoolOffsetY}%`
          // border: `1px solid white`
        }}
      >
        <div
          style={{
            width: `100%`,
            height: `100%`,
            position: "relative",
            left: `-50%`,
            top: `-50%`
            // border: `1px solid red`
          }}
        >
          <img
            src={Object.values(objFish.fish)[0]}
            alt={Object.keys(objFish.fish)[0]}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    ));
    const FishGrid = styled.div`
      position: absolute;
      left: 1.75%;
      top: 2%;
      width: 50%;
      height: 88%;
      /* border: 1px solid red; */
      img {
        width: 100%;
      }
    `;
    return <FishGrid>{schoolOfFish}</FishGrid>;
  };
  const Targets = props => {
    const preScaleX = 15;
    const preScaleY = 15;
    const targetWidth = 1;
    const targetHeight = 1;

    const offsetX = 6.35;
    const offsetY = 9.8;
    const numOfTargets = targetLocations.length;
    // console.log(numOfTargets);
    const targets = targetLocations.map((arrTarget, targetCount) => (
      <div
        className="target"
        key={targetCount}
        style={{
          width: `${targetWidth * preScaleX}%`,
          height: `${targetHeight * preScaleY}%`,
          position: "absolute",
          left: `${(((arrTarget[0] / 10) * 1920) / 1920) * 100 + offsetX}%`,
          top: `${((((9 - arrTarget[1]) / 10) * 1080) / 1080) * 100 + offsetY}%`
          // border: `1px solid white`
        }}
      >
        <div
          style={{
            width: `100%`,
            height: `100%`,
            position: "relative",
            left: `-50%`,
            top: `-50%`
            // border: `1px solid red`
          }}
        >
          <img
            src={targetCount < numOfTargets - 1 ? targetOpen : finalTarget}
            alt={"Target " + (targetCount + 1)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    ));
    const TargetGrid = styled.div`
      position: absolute;
      left: 1.75%;
      top: 2%;
      width: 50%;
      height: 88%;
      /* border: 1px solid red; */
      img {
        width: 100%;
      }
    `;
    return <TargetGrid>{targets}</TargetGrid>;
  };
  const Icon = props => {
    const IconDiv = styled.div`
      display: inline-block;
      position: absolute;
      width: 8%;
      height: 8%;
      img {
        position: relative;
        bottom: 20%;
        left: 20%;
        width: 100%;
      }
    `;
    return (
      <IconDiv>
        <img alt={props.alt} {...props} />
      </IconDiv>
    );
  };
  const ShowIntro = props => {
    return (
      <div className="video_tablet_container">
        <h1>
          AUV JOURNEY
          <Icon alt="Submarine Icon" src={subIcon} />
        </h1>

        <p>Code the AUV to go through the targets, without hitting any fish.</p>
        <div className="video_screen">
          <img alt="AUV Video" src={auvVideoStill} />
        </div>
        <p>
          Start each program with a RUN block and end it with an END block.
          Direction blocks move the ROV through the pool. LOOP repeats the code
          between two loop blocks. Use the drop-down menu to select the number
          of times you want to program that action.
        </p>
        <ButtonCenter>
          <AppButton {...{ onClick: () => mSend("CLICK") }}>Got it</AppButton>
        </ButtonCenter>
      </div>
    );
  };

  const AUVProgrammer = props => {
    const GameContainer = styled.div`
      position: absolute;
      width: 100%;
      width: calc(720 / 1920 * 100%);
      height: calc(780 / 1080 * 100%);
      right: calc(93 / 1920 * 100%);
      top: calc(144 / 1080 * 100%);

      h1 {
        display: inline-block;
        text-transform: uppercase;
        font-size: 1.5vw;
        font-weight: bold;
        font-style: normal;
        line-height: 140%;
        /* border: 1px solid red; */
      }
      h3 {
        display: inline-block;
        position: relative;
        left: 15%;
        width: 50%;
        text-transform: uppercase;
        font-size: 0.5vw;
        font-weight: bold;
        font-style: normal;
        line-height: 140%;
        opacity: 0.5;
        /* border: 1px solid red; */
      }
    `;

    const canSelect = () => {
      return runNeeded;
    };
    const COLLECTION = [
      {
        id: uuid(),
        label: "Motor On",
        icon: runIcon,
        selectable: canSelect()
      },
      {
        id: uuid(),
        label: "Motor Off",
        icon: stopIcon,
        selectable: !canSelect()
      },
      {
        id: uuid(),
        label: "Forward",
        distance: "1",
        icon: arrowIcon,
        selectable: !canSelect()
      },
      {
        id: uuid(),
        label: "Rise",
        distance: "1",
        icon: arrowIcon,
        rotate: 270,
        selectable: !canSelect()
      },
      {
        id: uuid(),
        label: "Back",
        distance: "1",
        icon: arrowIcon,
        rotate: 180,
        selectable: !canSelect()
      },
      {
        id: uuid(),
        label: "Dive",
        distance: "7",
        icon: arrowIcon,
        rotate: 90,
        selectable: !canSelect()
      }
      // { id: uuid(), label: "Loop", icon: loopIcon },
      // { id: uuid(), label: "End Loop", icon: endLoopIcon }
    ];

    const reorder = (list, startIndex, endIndex) => {
      const [removed] = list.splice(startIndex, 1);
      list.splice(endIndex, 0, removed);
      return list;
    };

    const copy = (
      source,
      destination,
      droppableSource,
      droppableDestination
    ) => {
      const item = source[droppableSource.index];
      destination.splice(droppableDestination.index, 0, {
        ...item,
        id: uuid()
      });
      return destination;
    };

    const onDragEnd = React.useCallback(
      result => {
        const { source, destination } = result;

        if (!destination) {
          return;
        }

        switch (source.droppableId) {
          case destination.droppableId:
            setProgramPadItems(state =>
              reorder(state, source.index, destination.index)
            );
            break;
          case "CONTROLPAD":
            setProgramPadItems(state =>
              copy(COLLECTION, state, source, destination)
            );
            if (programPadItems.length === 1) {
              if (programPadItems[0].label === "Run") {
                mSend("DRAGRUN");
                generateTimeline();
              }
            } else if (programPadItems.length > 1) {
              mSend("DRAGCOMMAND", {
                instructions: programPadItems
              });
              generateTimeline();
            }

            break;
          default:
            break;
        }
      },
      [
        // setProgramPadItems,
        // mSend,
        // commandItems,
        // setCommandItems,
        COLLECTION
        // programPadItems
      ]
    );
    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <GameContainer>
            <h1>
              AUV JOURNEY
              <Icon alt="Submarine Icon" src={subIcon} />
            </h1>
            <h3>
              Hint: Drag’n’drop code blocks onto the coding pad. When you are
              ready to try your code, hit RUN.
            </h3>
            <DragDropContext onDragEnd={onDragEnd}>
              <AUVControlPad items={COLLECTION} />
              <AUVProgramPad items={programPadItems} />
            </DragDropContext>

            <div className="auv_program_control">
              <button onClick={eraseProgramPad}>Erase Commands</button>
              <button onClick={runTimeline}>Run Program</button>
              <button onClick={() => mSend("DONE")}>Done</button>
            </div>
          </GameContainer>
        </DndProvider>
      </>
    );
  };

  const arrLocation = positionAUV;
  const preScale = 20;

  const offsetX = 5;
  const offsetY = 15.5;
  return (
    <div className="auv_page">
      <BackgroundImage />
      <ExplorerBadge objective="Explore MBARI" />
      <LargeTablet showTablet={true}>
        {showIntro && <ShowIntro />}
        {gameStarted && <AUVProgrammer />}
      </LargeTablet>
      <Fish />
      {gameStarted && <Targets />}
      <div className="auv_grid">
        <div
          className="auv_sub"
          ref={auvElement}
          style={{
            width: `${preScale}%`,
            height: `${preScale}%`,
            position: "absolute",
            left: `${(((arrLocation[0] / 10) * 1920) / 1920) * 100 + offsetX}%`,
            top: `${((((9 - arrLocation[1]) / 10) * 1080) / 1080) * 100 +
              offsetY}%`
            // border: `1px solid white`
          }}
        >
          <div
            style={{
              width: `100%`,
              height: `100%`,
              position: "relative",
              left: `-50%`,
              top: `-50%`
              // border: `1px solid red`
            }}
          >
            <img src={auv} alt="AUV" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AUVGame;
