function shuffle(array) {
  //fisherYates
  var count = array.length,
    randomnumber,
    temp;
  while (count) {
    randomnumber = (Math.random() * count--) | 0;
    temp = array[count];
    array[count] = array[randomnumber];
    array[randomnumber] = temp;
  }
}

export default class multipleChoice {
  constructor(quizItems) {
    this._quizItems = quizItems;
    this._total = this._quizItems.length;

    for (var i = 0; i < this._total; i++) {
      const quizItem = this._quizItems[i];
      const choicesRandom = Object.keys(quizItem.choices);
      shuffle(choicesRandom);
      // console.log("choices:", quizItem.choices[1]);
      this._quizItems[i].choices_random = choicesRandom.map(x => ({
        id: [x],
        question: quizItem.choices[x]
      }));
      // console.log(this._quizItems[i].choices_random);
    }
  }

  get Count() {
    console.log("total:", this._total);
    return this._total;
  }
  get QuizItems() {
    return this._quizItems;
  }

  Answers(itemNum) {
    return this._quizItems[itemNum].choices_random;
  }

  checkAnswer(qnum, anum) {
    console.log(qnum, anum);
    return this._quizItems[qnum].answer === anum;
  }

  calculateScore(userAnswers) {
    let score = 0;
    Object.keys(userAnswers).forEach(qnum => {
      let correct = 0;
      console.log(
        "_quizItems[",
        qnum,
        "].answers:",
        this._quizItems[qnum].answers
      );
      let answersCount = this._quizItems[qnum].answers.length;
      console.log(qnum);
      console.log(answersCount);
      console.log("userAnswers:", userAnswers[qnum]);

      // console.log(
      //   "Filtered Answers Are:",
      Object.keys(userAnswers[qnum]).forEach(answer => {
        // console.log("answer:", answer);
        console.log("answers:", this._quizItems[qnum].answers);
        console.log(
          "found: ",
          parseInt(answer),
          " in ",
          this._quizItems[qnum].answers,
          " ",
          this._quizItems[qnum].answers.includes(parseInt(answer))
        );
        if (userAnswers[qnum][answer] === true)
          if (this._quizItems[qnum].answers.includes(parseInt(answer)))
            correct += 1;
          else correct -= 1;
      });
      // );

      score += (correct * 1.0) / answersCount;
    });
    return (score / this._total) * 100;
  }
}
